import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftIcon,
  ExternalLinkIcon,
  Notification,
  SearchIcon,
} from "../../assets/icons";
import { Input } from "../../components/Input";
import { Link } from "../../components/Link";
import filterIcon from "../../assets/icons/Filtrar.svg";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import { Div, ModuleContainer, Row, Text } from "../../styles/Common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table } from "../../components/Table";
import useGetProjectActivity from "./hooks/useGetInvestorActivity";
import { Tooltip } from "antd";
import { Skeleton } from "../../components/Skeleton";
import { dateFormat } from "../../utilities/helpers";
import { ModalReminders } from "./components/ModalReminders";

export const ProjectActivity = () => {
  const { t } = useTranslation(["projectActivity", "common"]);
  const { id } = useParams();

  const { search: searchUseLocation } = useLocation();
  const projectType = new URLSearchParams(searchUseLocation).get("type");
  const navigate = useNavigate();
  const [activityList, setActivityList] = useState([]);
  const [cardsData, setCardsData] = useState({
    discardedTimes: 0,
    invitations: 0,
    noRegisteredInvestors: 0,
    teaserDownloads: 0,
  });
  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [totalItems] = useState(10);
  const [search, setSearch] = useState("");
  const [payload, setPayload] = useState({
    projectId: id,
    offset: pages.pageNumber,
    limit: pages.pageSize,
    query: search,
  });
  const { data, isSuccess, isLoading } = useGetProjectActivity(payload);
  const [showModal, setShowModal] = useState(false);

  const handleBack = () => {
    navigate("/projects");
  };

  const cardsInfo = [
    {
      title: t("totalInvitedInvestors"),
      quantity: cardsData.invitations,
      color: theme.colors.blue,
    },
    {
      title: t("noRegisteredInvestors"),
      quantity: cardsData.noRegisteredInvestors,
      color: theme.colors.green,
    },
    {
      title: t("downloadedTeaser"),
      quantity: cardsData.teaserDownloads,
      color: theme.colors.gray150,
    },
    {
      title: t("descartedProject"),
      quantity: cardsData.discardedTimes,
      color: theme.colors.gray300,
    },
  ];

  const columns = [
    {
      title: t("company"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("registered"),
      dataIndex: "registered",
      key: "registered",
      align: "center",
      render: () => t("yes"),
    },
    {
      title: t("downloadTeaser"),
      dataIndex: "downloadTeaser",
      key: "downloadTeaser",
      align: "center",
      render: (value) => (value === 0 ? "No" : t("yes")),
    },
    {
      title: t("tcRequest"),
      dataIndex: "tcRequest",
      key: "tcRequest",
      align: "center",
    },
    {
      title: t("tcSign"),
      dataIndex: "",
      key: "signedTermsAndConditions",
      align: "center",
      render: (value) => (value ? t("yes") : "No"),
    },
    {
      title: t("accessInfo"),
      dataIndex: "detailedInfoAccess",
      key: "detailedInfoAccess",
      align: "center",
      render: (value) => (value === 0 ? "No" : t("yes")),
    },
    {
      title: projectType === "sale" ? t("nboSended") : t("ndaRequested"),
      dataIndex: projectType === "sale" ? "nboSended" : "ndaRequested",
      key: projectType === "sale" ? "nboSended" : "ndaRequested",
      align: "center",
      render: (value) => (value === 0 ? "No" : t("yes")),
    },
    {
      title: t("questions"),
      dataIndex: "questions",
      key: "questions",
      align: "center",
      render: (value) => (value === 0 ? "No" : t("yes")),
    },
    {
      title: t("investorIntel"),
      dataIndex: "lastReminderDate",
      key: "lastReminderDate",
      align: "center",
      render: () => (
        <ExternalLinkIcon
          fill={theme.colors.green}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: t("lastActionDate"),
      dataIndex: "lastActionDate",
      key: "lastActionDate",
      render: (text) => dateFormat(text),
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="center" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => (
            <Tooltip
              key={i}
              title={action.tooltip}
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
              onClick={action.onClick}
            >
              {action.icon}
            </Tooltip>
          ))}
        </Div>
      ),
    },
  ];

  const handleReminders = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (isSuccess) {
      const info = data?.data.data;
      setCardsData(info.generalInformation);
      setActivityList(
        info.activities.map((ele, i) => ({
          key: i,
          ...ele,
          ...ele.actions,
          actions: [
            {
              id: ele._id,
              name: ele.name,
              tooltip: t("sendReminder"),
              onClick: handleReminders,
              icon: (
                <Notification
                  width="32px"
                  height="32px"
                  fill={theme.colors.green}
                />
              ),
            },
          ],
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  const handleChangeTable = (pagination) => {
    setPages({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setPayload({
        ...payload,
        query: search,
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <ModuleContainer direction="column" padding="11px 69px 131px 32px">
      <ModalReminders showModal={showModal} handleClose={handleClose} />
      <Row
        align="center"
        m="0 0 23px 0"
        style={{ cursor: "pointer" }}
        gap="6px"
        onClick={handleBack}
      >
        <ArrowLeftIcon />
        <Text weight={theme.fonts.weight.medium} color={theme.colors.green}>
          Volver atrás
        </Text>
      </Row>
      <Div justify="space-around" m="0 0 36px 0" width="100%">
        {cardsInfo.map((ele, indx) => (
          <Div
            key={indx}
            height="118px"
            width="235px"
            p="0 21px 0 20px"
            justify="space-around"
            align="center"
            style={{ borderRadius: "18px", background: ele.color }}
          >
            <Text
              color={indx === 2 ? theme.colors.gray500 : theme.colors.white}
              size={theme.fonts.size.h6}
              style={{ textAlign: "start", width: "112px" }}
            >
              {ele.title}
            </Text>
            <Text
              color={indx === 2 ? theme.colors.gray500 : theme.colors.white}
              size={theme.fonts.size.h1i}
              weight={theme.fonts.weight.bold}
            >
              {ele.quantity}
            </Text>
          </Div>
        ))}
      </Div>
      <Div justify="space-between" align="center" width="100%" m="0 0 39px 0">
        <Text
          color={theme.colors.gray500}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.semibold}
        >
          {t("investorsActivity")}
        </Text>
        <Div gap="32px" align="center">
          <Input
            width="254px"
            placeholder={t("common:search")}
            suffix={<SearchIcon />}
            colorPlaceholder={theme.colors.gray200}
            radius="23px"
            value={search}
            onChange={handleChange}
            onPressEnter={handleKeyDown}
          />
          <Link
            icon={<Notification fill={theme.colors.green} />}
            onClick={handleReminders}
          >
            {t("reminders")}
          </Link>
          <Button width={"112px"} variant={"outlined"} icon>
            {t("filter")}
            <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
          </Button>
        </Div>
      </Div>
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        data={activityList}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={{
          defaultCurrent: 1,
          ...pages,
          total: totalItems,
          position: ["bottomRight"],
        }}
        onChange={handleChangeTable}
        rowSelection={{
          type: "checkbox",
        }}
        shadow={"none"}
        padding={"0px"}
        style={{ width: "75vw" }}
        locale={{
          emptyText: isLoading ? (
            <Div justify="center" gap="16px">
              {[...Array(9)].map((item, idx) => (
                <Skeleton
                  key={idx}
                  title={false}
                  paragraph={{
                    rows: 10,
                    width: "100%",
                  }}
                  loading
                />
              ))}
            </Div>
          ) : (
            "No Data"
          ),
        }}
      />
    </ModuleContainer>
  );
};
