import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { BackgroundImg, ColorCard, GreyCard, MiniCard } from "../styles";
import { getUserInfo } from "../../../utilities/helpers";
import { ModalImage } from "./modalImage";

export const Content = ({
  source,
  generalCharacteristics,
  dates,
  originFunds,
  totals,
  financing,
  sizingParameters,
  irr,
  images,
}) => {
  const { t } = useTranslation(["financialModel", "common"]);
  const role = getUserInfo().role[0];
  const [showImg, setShowImg] = useState(false);
  const [financialImg, setFinancialImg] = useState(null);
  const [indicative, setIndicative] = useState(null);

  const titleProps = {
    color: theme.colors.blue,
    size: theme.fonts.size.h6,
    weight: theme.fonts.weight.medium
  };

  const labelsProps = {
    color: theme.colors.gray500,
    size: theme.fonts.size.sm,
    weight: theme.fonts.weight.medium
  };

  const valuesProps = {
    color: theme.colors.gray300,
    size: theme.fonts.size.sm,
  };

  const handleOpenImage = (img, ind) => {
    setShowImg(true);
    setFinancialImg(img);
    setIndicative(ind)
  };

  const handleCloseModal = () => {
    setShowImg(false);
    setFinancialImg(null);
    setIndicative(null);
  };

  return (
    <Row gutter={[16, 24]}>
      <ModalImage 
        showModal={showImg}
        handleCloseModal={handleCloseModal}
        financialImg={financialImg}
        indicative={indicative}
      />
      <Col md={9}>
        <Card
          shadow={"none"}
          padding={"17px 22px 10px 22px"}
        >
          <Text {...titleProps} mb="16px">
            {t("generalCharacteristics")}
          </Text>
          {generalCharacteristics.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
          <Text {...titleProps} mt="24px" mb="16px">
            {t("dates")}
          </Text>
          {dates.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
        </Card>
      </Col>
      <Col md={15}>
        <BackgroundImg height='100%' >
          <img 
            alt="img12" 
            src={role === 'Developer' ? images?.img1 : images?.img2} 
            height={'165%'}
            width={'100%'}
            style={{ 
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translate(-50%, -32%)',              
              clipPath: 'inset(35% 3% 12% 5%)',
              cursor: 'pointer'
            }} 
            onClick={() => handleOpenImage(role === 'Developer' ? images?.img1 : images?.img2, 1)}
          />
        </BackgroundImg>
      </Col>
      <Col md={9}>
        <Card
          shadow={"none"}
          padding={"18px 24px 8px 20px"}
        >
          <Text {...titleProps} mb="16px">
            {t("originFunds")}
          </Text>
          {originFunds.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
          <hr 
            style={{ 
              width: "100%", 
              border: `1px solid ${theme.colors.gray100}`,
              margin: "22px 0 23px 0"
            }} 
          />
          {totals.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text 
                {...labelsProps} 
                color={indx === 0 ? theme.colors.green : theme.colors.gray500}
              >
                {ele.label}
              </Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
        </Card>
      </Col>
      <Col md={15}>
        <BackgroundImg height='70%'>
          <img 
            alt="img4" 
            src={images?.img4} 
            height={'165%'}
            width={'100%'}
            style={{ 
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translate(-50%, -38%)',             
              clipPath: 'inset(40% 6% 9% 6%)',
              cursor: 'pointer'
            }} 
            onClick={() => handleOpenImage(images?.img4, 2)}
          />
        </BackgroundImg>
      </Col>
      <Col md={24}>
        <Card
          shadow={"none"}
          padding={"32px 39px 34px 32px"}
          style={{ margin: source !== "page" ? "0 0 46px 0" : "0px" }}
        >
          <Row gutter={[25, 0]}>
            <Col md={12}>
              <Text {...titleProps} mb="24px">
                {t("financing")}
              </Text>
              <Row gutter={[18, 16]}>
                {financing.map((ele, indx) => (
                  <Col key={indx} md={12}>
                    <ColorCard style={{ background: ele.color }}>
                      <MiniCard>
                        {ele.icon}
                      </MiniCard>
                      <Div direction="column" gap="6px">
                        <Text 
                          weight={theme.fonts.weight.medium}
                          color={theme.colors.white}
                        >
                          {ele.label}
                        </Text>
                        <Text
                          color={theme.colors.white}
                          size={theme.fonts.size.sm}
                        >
                          {ele.value}
                        </Text>
                      </Div>
                    </ColorCard>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={12}>
              <Text {...titleProps} mb="24px">
                {t("sizingParameters")}
              </Text>
              <GreyCard>
                {sizingParameters.map((ele, indx) => (
                  <>
                    <Div key={indx} justify="space-between" m="0 0 12px 0">
                      <Text {...labelsProps}>{ele.label}</Text>
                      <Text {...valuesProps}>{ele.value}</Text>
                    </Div>
                  </>
                ))}
              </GreyCard>
            </Col>
            <Col md={24} style={{ marginTop:"24px" }}>
              <GreyCard>
                <Text {...titleProps} mb="24px">
                  {t("irr")}
                </Text>
                {irr.map((ele, indx) => (
                  ele.permission && (
                    <Div key={indx} justify="space-between" m="0 0 12px 0">
                      <Text {...labelsProps}>{ele.label}</Text>
                      <Text {...valuesProps}>{ele.value}</Text>
                    </Div>
                  )
                ))}
              </GreyCard>
            </Col>
          </Row>
        </Card>
      </Col>
      {source === "page" && (
        <>
          <Div width="100%" justify="center">
            <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
              {t("common:poweredBy")}
            </Text>
          </Div>
        </>
      )}
    </Row>
  )
};

Content.propTypes = {
  source: PropTypes.string,
  generalCharacteristics: PropTypes.array,
  dates: PropTypes.array,
  originFunds: PropTypes.array,
  totals: PropTypes.array,
  financing: PropTypes.array,
  sizingParameters: PropTypes.array,
  irr: PropTypes.array,
  refinancingHypothesisTime: PropTypes.array,
  refinancingHypothesis: PropTypes.array,
  images: PropTypes.object,
};
