import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { FormatNumber } from "../../../../utilities/numberFunctions/handleChange";
import { RichTextArea } from "../../../../components/RichTextArea";

export const Alter5 = ({
  strategyData,
  tabActiveKey,
  handleChangeNumberStrategy,
  handleChangeRichText
}) => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const locale = i18n.language;
  return (
    <>
      <Row gap="23px" m="33.5px 0 24px 0">
        <Input
          label={t("openingFee")}
          id="openingFee"
          width="30%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].platformFee.openingFee,
            locale
          )}
          onChange={(event) =>
            handleChangeNumberStrategy(
              event,
              "platformFee",
              locale,
              "percentage"
            )
          }
        />
        <Input
          label={t("runningFee")}
          id="runningFee"
          width="30%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].platformFee.runningFee,
            locale
          )}
          onChange={(event) =>
            handleChangeNumberStrategy(
              event,
              "platformFee",
              locale,
              "percentage"
            )
          }
        />
      </Row>
      <Row gap="23px">
        <RichTextArea
          label={t("alter5PlatformFeeText")}
          id="alter5PlatformFeeText"
          width="100%"
          height="138px"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].platformFee
              .alter5PlatformFeeText
          }
          onChange={(value) => handleChangeRichText(value, "alter5PlatformFeeText", "platformFee")}
        />
      </Row>
    </>
  );
};

Alter5.propTypes = {
  strategyData: PropTypes.array,
  tabActiveKey: PropTypes.string,
  handleChangeNumberStrategy: PropTypes.func,
  handleChangeRichText: PropTypes.func,
};
