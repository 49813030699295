import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { EuroIcon } from "../../../assets/icons";
import { FinancingTenorOptions } from "../../../utilities/strategiesOptions";
import { formatNumber } from "../../../utilities/helpers";

export const BasicInformation = ({
  sourceForm,
  handleChange,
  handleChangeNumber,
  handleSelect,
  handleRadio,
  strategiesOptions,
  investorsOptions,
}) => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;

  return (
    <Row gutter={[32, 32]} style={{ width: "100%" }}>
      <Col md={8}>
        <Input
          id="name"
          label={t("basicInfoForm.sourceName")}
          value={sourceForm.name}
          onChange={handleChange}
        />
      </Col>
      <Col md={8}>
        <Select
          label={t("basicInfoForm.strategy")}
          placeholder={t("select")}
          options={strategiesOptions}
          value={sourceForm.strategyId}
          onChange={(e) => handleSelect(e, "strategyId")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Select
          label={t("basicInfoForm.investor")}
          placeholder={t("select")}
          options={investorsOptions}
          value={sourceForm.investorId}
          onChange={(e) => handleSelect(e, "investorId")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Input
          id="minFinancingTicket"
          label={t("basicInfoForm.ticketMin")}
          value={
            formatNumber(sourceForm.minFinancingTicket, locale) === "0"
              ? ""
              : formatNumber(sourceForm.minFinancingTicket, locale)
          }
          onChange={handleChangeNumber}
          suffix={<EuroIcon fill={theme.colors.blue} />}
        />
      </Col>
      <Col md={8}>
        <Input
          id="maxFinancingTicket"
          label={t("basicInfoForm.ticketMax")}
          value={
            formatNumber(sourceForm.maxFinancingTicket, locale) === "0"
              ? ""
              : formatNumber(sourceForm.maxFinancingTicket, locale)
          }
          onChange={handleChangeNumber}
          suffix={<EuroIcon fill={theme.colors.blue} />}
        />
      </Col>
      <Col md={8}>
        <Select
          label={t("basicInfoForm.financingTerm")}
          placeholder={t("select")}
          options={FinancingTenorOptions}
          value={sourceForm.merchantFinancingTenor}
          onChange={(e) => handleSelect(e, "merchantFinancingTenor")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={12}>
        <Div direction="column" align="start" gap="16px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            align="left"
          >
            {t("basicInfoForm.financingMerchant")}
          </Text>
          <RadioGroup
            name="isShort"
            gap="50px"
            value={sourceForm.isShort}
            onChange={handleRadio}
          >
            <Radio label={t("basicInfoForm.partial")} value={true} />
            <Radio label={t("basicInfoForm.totally")} value={false} />
          </RadioGroup>
        </Div>
      </Col>
    </Row>
  );
};

BasicInformation.propTypes = {
  sourceForm: PropTypes.object,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRadio: PropTypes.func,
  strategiesOptions: PropTypes.array,
  investorsOptions: PropTypes.array,
};
