import React, { useState, useCallback, useEffect } from "react";
import ReactLoading from "react-loading";
import { useLocation, useNavigate } from "react-router-dom";
import { ModuleContainer, Row, Col, Text, Div } from "../../styles/Common";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import userAdd from "../../assets/icons/User-add.svg";
import { Button } from "../../components/Button";
import useGetInformationDeclaration from "./hooks/useGetInformationDeclaration";
import usePostInformationDeclaration from "./hooks/usePostInformationDeclaration";
import toast from "react-hot-toast";
import RadioRow from "./components/RadioRow";
import { InviteCollaboratorModal } from "../Documents/components/inviteCollaboratorModal";
import { SendInvitationModal } from "../Documents/components/sendInvitationModal";
import useGetAnswersDeclaration from "./hooks/useGetAnswersDeclaration";
import useUpdateInformationDeclaration from "./hooks/useUpdateInformationDeclaration";
import { Link } from "../../components/Link";
import arrowBack from "../../assets/icons/arrow-left.svg";

export const InformationDeclaration = () => {
  const { t } = useTranslation("informationDeclaration");
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data: informationDeclaration, isLoading, isSuccess: isSuccessInformationDeclaration } =
    useGetInformationDeclaration();
  const sendInformationDeclaration = usePostInformationDeclaration();
  const updateInformationDeclaration = useUpdateInformationDeclaration();
  const projectId = new URLSearchParams(search).get("projectId");
  const projectName = new URLSearchParams(search).get("projectName");
  const objective = new URLSearchParams(search).get("objective");
  const projectType = new URLSearchParams(search).get("projectType");
  const financingType = new URLSearchParams(search).get("financingType");
  const { data, isSuccess, isLoading: isLoadingAnswersDeclaration, refetch } = useGetAnswersDeclaration(projectId);
  const [radioValues, setRadioValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalSendInvitation, setShowModalSendInvitation] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [informationDeclarationData, setInformationDeclarationData] = useState([]);

  useEffect(() => {
    if (isSuccessInformationDeclaration && isSuccess) {
      const info = data?.data?.result?.data;
      const informationalDeclarationId = info?.map((ele, indx) => ({
        key: indx,
        ...ele.informationalDeclarationId,
        id: ele._id,
      }))

      if (info.length > 0) {
        setInformationDeclarationData(informationalDeclarationId);
      } else {
        setInformationDeclarationData(informationDeclaration?.result?.data);
      }

    }
  }, [isSuccessInformationDeclaration, isSuccess, data, informationDeclaration]);

  useEffect(() => {
    if (isSuccess) {
      const info = data?.data?.result?.data;

      const resultArray = info?.map((ele, indx) => ({
        key: indx,
        id: ele._id,
        obtained: ele.obtained,
        notObtained: ele.notObtained,
        requested: ele.requested,
        na: ele.na,
        informationDeclarationId: ele.informationalDeclarationId?._id,
      }))

      const result = resultArray.reduce((acc, item) => {
        const { informationDeclarationId, obtained, notObtained, requested, na } = item;
        if (obtained) acc[informationDeclarationId] = "obtained";
        else if (notObtained) acc[informationDeclarationId] = "notObtained";
        else if (requested) acc[informationDeclarationId] = "requested";
        else if (na) acc[informationDeclarationId] = "na";
        return acc;
    }, {});

      setRadioValues(result);
      setIsUpdated(info.length > 0);
    }
  }, [isSuccess, data]);

  const handleRadioChange = (itemId, optionKey) => {
    setRadioValues((prev) => ({ ...prev, [itemId]: optionKey }));
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowModalSendInvitation = () => {
    handleCloseModal();
    setShowModalSendInvitation(true);
  };

  const handleCloseModalSendInvitation = () =>
    setShowModalSendInvitation(false);

  const RadioOptions = ["obtained", "notObtained", "requested", "na"];

  const allQuestionsAnswered = useCallback(() => {
    return informationDeclarationData.every(
      (item) => radioValues[item._id] !== undefined
    );
  }, [informationDeclarationData, radioValues]);

  const handleDataSubmission = useCallback(() => {
    if (!allQuestionsAnswered()) {
      toast.error(t("answerAllQuestions"));
      return;
    }
    const formattedData = informationDeclarationData?.map((item) => ({
      projectId: projectId,
      questionId: item._id,
      obtained: radioValues[item._id] === "obtained",
      notObtained: radioValues[item._id] === "notObtained",
      requested: radioValues[item._id] === "requested",
      na: radioValues[item._id] === "na",
      id: item.id,
    }));

    const action = isUpdated ? updateInformationDeclaration : sendInformationDeclaration;

    action.reset();
    action.mutate(formattedData, {
      onSuccess: () => {
        toast.success(t("common:saveChanges"));
        refetch();
        navigate(
          `/projects/edit-project?objective=${objective}&projectType=${projectType}&financingType=${financingType}&projectId=${projectId}&projectName=${projectName}&selected=plants`
        );
      },
      onError: (err) => {
        const message =
          err.response?.data?.result?.errorMessage?.message ||
          err.response?.data?.result?.code ||
          "errorOccurred";
        toast.error(t(`common:${message}`));
      },
    });
  }, [
    allQuestionsAnswered,
    radioValues,
    informationDeclarationData,
    projectId,
    financingType,
    navigate,
    objective,
    projectName,
    projectType,
    sendInformationDeclaration,
    updateInformationDeclaration,
    t,
    isUpdated,
    refetch,
  ]);

  if (isLoading || isLoadingAnswersDeclaration) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  const handleBack = () => {
    navigate(
      `/projects/edit-project?objective=${objective}&projectType=${projectType}&financingType=${financingType}&projectId=${projectId}&projectName=${projectName}`
    );
  };

  return (
    <ModuleContainer direction="column" padding="23px 69px 0 32px">
      <Div justify="flex-start" align="flex-start" m="0 0 21px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("goBack")}
        </Link>
      </Div>
      <Row justify="space-between" width="100%" m="0 0 32px 0">
        <Col flex="3">
          <Text
            size={theme.fonts.size.h5}
            weight={theme.fonts.weight.medium}
            color={theme.colors.blue}
          >
            {t("informationDeclarative")}
          </Text>
        </Col>
        <Row align="center" gap="24px">
          <Button
            variant={"outlined"}
            icon={<img alt="add" src={userAdd} />}
            width={"212px"}
            onClick={handleShowModal}
          >
            {t("inviteCollaborator")}
          </Button>
        </Row>
      </Row>
      <Card
        width="100%"
        height="min-content"
        padding={"28px 36.5px 49px 38.5px"}
      >
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ marginBottom: "1rem" }}>
              <th style={{ width: "50%", textAlign: "left" }}>
                <Text
                  color={theme.colors.blue}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.medium}
                >
                  {t("projectDocumentation")}
                </Text>
              </th>
              {RadioOptions?.map((option) => (
                <th key={option}>
                  <Text
                    color={theme.colors.blue}
                    size={theme.fonts.size.h6}
                    weight={theme.fonts.weight.medium}
                  >
                    {t(option)}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <Div m="1rem"></Div>
          <tbody>
            {informationDeclarationData?.map((item) => (
              <>
                <RadioRow
                  key={item._id}
                  item={item}
                  radioValues={radioValues}
                  handleRadioChange={handleRadioChange}
                  radioOptions={RadioOptions}
                />
              </>
            ))}
          </tbody>
        </table>
      </Card>
      <Div m="2rem 0 2rem 0">
        <Button
          width={"212px"}
          onClick={handleDataSubmission}
          loading={sendInformationDeclaration.isLoading || updateInformationDeclaration.isLoading}
        >
          {t("sendInformation")}
        </Button>
      </Div>
      {showModal && (
        <InviteCollaboratorModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowModalSendInvitation={handleShowModalSendInvitation}
          projectId={projectId}
        />
      )}
      {showModalSendInvitation && (
        <SendInvitationModal
          showModal={showModalSendInvitation}
          handleCloseModalSendInvitation={handleCloseModalSendInvitation}
        />
      )}
    </ModuleContainer>
  );
};
