import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Div, ModuleContainer } from "../../styles/Common";
import { Link } from "../../components/Link";
import { useTranslation } from "react-i18next";
import arrowBack from "../../assets/icons/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/Tabs";
import { Button } from "../../components/Button";
import { Card } from "./styles";
import { BondProgram } from "./components/bondProgram";
import { SizingParameters } from "./components/sizingParams";
import { Intermediation } from "./components/intermediation";
import useGetSource from "../UpdateSource/hooks/useGetSource";
import { theme } from "../../styles/theme";
import useGetSourceByProject from "./hooks/useGetSourceByProject";
import useUpdateSource from "../UpdateSource/hooks/useUpdateSource";
import toast from "react-hot-toast";
import { StringToNumber } from "../../utilities/numberFunctions/stringToNumber";
import { ModalUpdate } from "./components/modalUpdate";

export const UpdateSourceByProject = () => {
  const { t } = useTranslation("strategies");
  const navigate = useNavigate();
  const update = useUpdateSource();
  const { id, projectId } = useParams();
  const { data, isSuccess, isLoading } = useGetSource(id);
  const { data: dataSource, isSuccess: isSuccessSource, isLoading: isLoadingSource } = useGetSourceByProject(id, projectId);
  const [activeKey, setActiveKey] = useState("1");
  const [showModal, setShowModal] = useState(false);
  const [originalSourceForm, setOriginalSourceForm ] = useState({
    investorId: null,
    legalTenor: "",
    interest: null,
    makeWholeComission: "",
    debtPayment: null,
    financingOpeningFee: "",
    financingSpread: "",
    cashSweepActivation: false,
    priceCurveScenario: null,
    DSCRForMerchant: "",
    maximumLeverage: "",
    intermediationOpeningFee: "",
    intermediationSpread: "",
  });
  const [sourceForm, setSourceForm ] = useState({
    investorId: null,
    legalTenor: "",
    interest: null,
    makeWholeComission: "",
    debtPayment: null,
    financingOpeningFee: "",
    financingSpread: "",
    cashSweepActivation: false,
    priceCurveScenario: null,
    DSCRForMerchant: "",
    maximumLeverage: "",
    intermediationOpeningFee: "",
    intermediationSpread: "",
  });

  useEffect(() => {
    if (isSuccessSource) {
      const info = dataSource?.data;

      setSourceForm({
        investorId: info?.investorId || "",
        legalTenor: info?.legalTenor || "",
        interest: info?.interest || "",
        makeWholeComission: info?.makeWholeComission || "",
        debtPayment: info?.debtPayment || "",
        financingOpeningFee: info?.financingOpeningFee || "",
        financingSpread: info?.financingSpread || "",
        cashSweepActivation: info?.cashSweepActivation || false,
        priceCurveScenario: info?.priceCurveScenario || "",
        DSCRForMerchant: info?.DSCRForMerchant || "",
        maximumLeverage: info?.maximumLeverage || "",
        intermediationOpeningFee: info?.intermediationOpeningFee || "",
        intermediationSpread: info?.intermediationSpread || "",
      })
    }
  }, [isSuccessSource, dataSource]);

  useEffect(() => {
    if (isSuccess) {
      const info = data?.data;

      setOriginalSourceForm({
        investorId: info?.investorId || "",
        legalTenor: info?.legalTenor || "",
        interest: info?.interest || "",
        makeWholeComission: info?.makeWholeComission || "",
        debtPayment: info?.debtPayment || "",
        financingOpeningFee: info?.financingOpeningFee || "",
        financingSpread: info?.financingSpread || "",
        cashSweepActivation: info?.cashSweepActivation || false,
        priceCurveScenario: info?.priceCurveScenario || "",
        DSCRForMerchant: info?.DSCRForMerchant || "",
        maximumLeverage: info?.maximumLeverage || "",
        intermediationOpeningFee: info?.intermediationOpeningFee || "",
        intermediationSpread: info?.intermediationSpread || "",
      })
    }
  }, [isSuccess, data]);

  const handleBack = () => {
    navigate("/projects");
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleSelect = (value, id) => {
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value;
    setSourceForm(newSourceForm);
  };

  const panels = [
    {
      key: '1',
      tab: t("bondProgram"),
      children: 
        <BondProgram 
          sourceForm={sourceForm} 
          originalSourceForm={originalSourceForm} 
          setSourceForm={setSourceForm} 
          handleSelect={handleSelect}
        />,
    },
    {
      key: '2',
      tab: t("sizingParameters"),
      children: 
        <SizingParameters 
          sourceForm={sourceForm} 
          originalSourceForm={originalSourceForm} 
          setSourceForm={setSourceForm} 
          handleSelect={handleSelect}
        />,
    },
    {
      key: '3',
      tab: t("intermediation"),
      children: 
        <Intermediation 
          sourceForm={sourceForm} 
          originalSourceForm={originalSourceForm} 
          setSourceForm={setSourceForm} 
        />,
    },
  ];

  const handleUpdate = () => {
    setShowModal(true);
  };

  const handleConfirmUpdate = (updateModel) => {
    const payload = {
      investorId: sourceForm?.investorId,
      legalTenor: StringToNumber(sourceForm?.legalTenor),
      interest: sourceForm?.interest,
      makeWholeComission: StringToNumber(sourceForm?.makeWholeComission),
      debtPayment: sourceForm?.debtPayment,
      financingOpeningFee: StringToNumber(sourceForm?.financingOpeningFee),
      financingSpread: StringToNumber(sourceForm?.financingSpread),
      cashSweepActivation: sourceForm?.cashSweepActivation,
      priceCurveScenario: sourceForm?.priceCurveScenario,
      DSCRForMerchant: StringToNumber(sourceForm?.DSCRForMerchant),
      maximumLeverage: StringToNumber(sourceForm?.maximumLeverage),
      intermediationOpeningFee: StringToNumber(sourceForm?.intermediationOpeningFee),
      intermediationSpread: StringToNumber(sourceForm?.intermediationSpread),
      updateModel: updateModel,
    };

    update.reset();
    update.mutate({...payload, id: id, projectId: projectId}, {
      onSuccess: () => {
        toast.success(t("editedSuccess"));
        navigate("/projects");
      },
      onError: (err) => {
        toast.error(t(`common:${err.response.data.result.code}`));
      },
    });
  };

  if (isLoading || isLoadingSource) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    )
  }

  return (
    <ModuleContainer direction="column" padding="0px 69px 52px 32px">
      <ModalUpdate 
        showModal={showModal} 
        handleCloseModal={() => setShowModal(false)} 
        handleUpdate={handleConfirmUpdate}
      />
      <Div m="16px 0 36px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("backAndCancel")}
        </Link>
      </Div>
      <Div direction="column">
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
          {panels.map((panel) => (
            <Tabs.Panel tab={panel.tab} key={panel.key}>
              <Card>
                {panel.children}
              </Card>
            </Tabs.Panel>
          ))}
        </Tabs>
        <Div m="44px 0 0 0">
          <Button width="206px" onClick={handleUpdate}>
            {t("saveInfo")}
          </Button>
        </Div>
      </Div>
    </ModuleContainer>
  )
};