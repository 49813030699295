import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Div, Text } from "../../../../styles/Common";
import Drawer from "../../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { theme } from "../../../../styles/theme";
import { AlertIcon, ArrowRight } from "../../../../assets/icons";
import messagesImg from "../../../../assets/images/messages.svg";
import { useDispatch, useSelector } from "react-redux";
import { WrapperIcon } from "../DrawerAlert/styles";
import useCreateIncidences from "../../hooks/useCreateIncidence";
import { setIncidences } from "../../../../core/store/projects";
import { DrawerAlert } from "../DrawerAlert";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { getUserInfo } from "../../../../utilities/helpers";

export const DrawerAllIncidences = ({ 
    openDrawer, 
    handleClose, 
    incidencesList, 
    projectId
  }) => {
  const { t } = useTranslation("newProject");
  const queryClient = useQueryClient();
  const role = getUserInfo().role[0];
  const dispatch = useDispatch();
  const { incidences } = useSelector((state) => state.projects);
  const sendRFA = useCreateIncidences();
  const [openDrawerAlert, setOpenDrawerAlert] = useState(false);
  const [field, setField] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [allIncidences, setAllIncidences] = useState([]);
  const [plantsSource, setPlantsSource] = useState(false);

  useEffect(() => {
    setAllIncidences([...incidencesList, ...incidences]);
  }, [incidencesList, incidences]);

  const transformedIncidences = allIncidences.reduce((acc, item) => {
    const { projectField, ...rest } = item;
    const existingField = acc.find(field => field.fieldName === projectField);
    if (existingField) {
        existingField.incidences.push(rest);
    } else {
        acc.push({ fieldName: projectField, incidences: [rest] });
    }
    return acc;
  }, []);

  const handleSendRFA = () => {
    const filteredIncidences = incidences.filter((incidence) => {
      return !incidence.incidenceId || !incidence.status;
    }).map((incidence) => {
      return {
        comment: incidence.comment,
        projectId: incidence.projectId,
        projectField: incidence.projectField,
        ...(incidence.incidenceId && { incidenceId: incidence.incidenceId }),
        ...(incidence.plantId && { plantId: incidence.plantId }),
      }
    });

    sendRFA.reset();
    sendRFA.mutate(filteredIncidences, {
      onSuccess: () => {
        dispatch(setIncidences([]));
        toast.success(t("alertRFA.success"));
        handleClose();
        queryClient.invalidateQueries(["incidencesInfo", projectId]);
      },
      onError: (err) => {
        err.response.data.result.code.message.map((message) =>
          toast.error(message)
        );
      },
    });
  };

  const plantsFields = ["Superficie de la planta", "Potencia pico", "Coste de arrendamiento anual"];

  const handleOpenDrawerAlert = (projectField, fieldName) => {
    if (plantsFields.includes(fieldName)) {
      setPlantsSource(true);
    } else {  
      setPlantsSource(false);
    }
    setField(fieldName);
    setFieldId(projectField);
    setOpenDrawerAlert(true);
    handleClose();
  };

  const handleCloseDrawer = () => {
    setOpenDrawerAlert(false);
  };

  const handleCloseDrawerAll = () => {
    handleClose();
  };

  return (
    <Drawer
      title={t("alertRFA.title")}
      open={openDrawer}
      onClose={handleCloseDrawerAll}
    >
      <DrawerAlert
        openDrawer={openDrawerAlert} 
        handleClose={handleCloseDrawer} 
        field={field}
        fieldId={fieldId}
        incidencesList={incidencesList}
        plantsSource={plantsSource}
      />
      <Div direction="column" height="100%" justify="space-between">
        <Div direction="column">
          <Text 
            color={theme.colors.gray500}
            mt="16px" 
            mb="32px"
          >
            {t("alertRFA.subtitle")}
          </Text>
          <Div justify="center" m="39px 0 0 0">
            {allIncidences.length > 0 ? (
              <Div width="100%" direction="column" gap="24px">
                {transformedIncidences?.map((incidence, indx) => (
                  <>
                    <Div
                      key={indx}
                      width="100%"
                      height="52px"
                      align="flex-start"
                      justify="space-between"
                      onClick={() => handleOpenDrawerAlert(incidence.fieldName, incidence.incidences[0].fieldName)}
                      style={{ borderBottom: `1px solid ${theme.colors.gray100}`, cursor: "pointer" }}
                    >
                      <Div gap="9px">
                        <WrapperIcon>
                          <AlertIcon fill={theme.colors.yellow700} width={"24px"} height={"24px"} />
                        </WrapperIcon>
                        <Text mt="6px" color={theme.colors.yellow700}>
                          {incidence.incidences[0].fieldName} { 
                            (incidence.incidences[0].fieldName === "Potencia pico" || 
                            incidence.incidences[0].fieldName === "Coste de arrendamiento anual") 
                            ? t("alertRFA.plants") : null 
                          }
                        </Text>
                      </Div>
                      <ArrowRight fill={theme.colors.gray500} />
                    </Div>
                  </>
                ))}
              </Div>
            ) : (
              <Div direction="column" width="100%" align="center" justify="center" gap="14px">
                <img alt="messages" src={messagesImg} style={{ opacity: 0.3 }} />
                <Text 
                  color={theme.colors.gray100}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.medium}
                  mb="37px"
                >
                  {t("alertRFA.noIncidences")}
                </Text>
              </Div>
            )}
          </Div>
        </Div>
        <Div direction="column" gap="16px" width="100%" align="center">
          {role === 'Manager' && (
            <Button 
              width={"221px"} 
              onClick={handleSendRFA} 
              disabled={sendRFA.isLoading || incidences.length === 0}
            >
              {t("alertRFA.send")}
            </Button>
          )}
          <Link onClick={handleClose}>{t("alertRFA.cancel")}</Link>
        </Div>
      </Div>
    </Drawer>
  )
};

DrawerAllIncidences.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  field: PropTypes.string,
  fieldId: PropTypes.string,
  incidencesList: PropTypes.array,
  projectId: PropTypes.string,
};