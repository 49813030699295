import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Text, Row, Col, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Card } from "../../../components/Card";
import { EmailFillIcon } from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import useGetProjectRequests from "../hooks/useGetProjectRequests";
import { dateFormat } from "../../../utilities/helpers";
import { Tooltip } from "antd";
import { SwapOutlined } from "@ant-design/icons";

export const PendingRequest = ({
  setPreSelectedCompany,
  setShowModalAssociateComapany,
  setSelectedProjectReq,
}) => {
  const { t } = useTranslation("scraper");

  const projectRequests = useGetProjectRequests();
  const { data, isSuccess } = projectRequests;
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setRequestList(data?.data?.data);
    }
  }, [data, isSuccess]);

  const handleSearch = (user) => {
    setPreSelectedCompany({});
    setSelectedProjectReq(user);
    setShowModalAssociateComapany(true);
  };

  return (
    <>
      <Row m="32px 0">
        <Text color={theme.colors.gray300}>{t("promotersAlter")}</Text>
      </Row>
      <Row
        style={{ display: "flex", flexWrap: "wrap" }}
        gap="24px"
        width="100%"
      >
        {requestList.map((item, index) => (
          <Card
            width="30%"
            height="105px"
            padding="14px 15px 19px 13px"
            key={index}
          >
            <Row gap="8px">
              <Col justify="center" align="center">
                <Div
                  background={theme.colors.gray100}
                  width="50px"
                  height="50px"
                  radius="50px"
                  align="center"
                  justify="center"
                >
                  <Text
                    color={theme.colors.blue}
                    weight={theme.fonts.weight.semibold}
                  >
                    {_.map(item.userName.split(" "), (name) =>
                      _.upperFirst(_.head(name))
                    )
                      .slice(0, 2)
                      .join("")}
                  </Text>
                </Div>
              </Col>
              <Col width="100%">
                <Row justify="space-between" align="end" m=" 0 0 2px 0">
                  <Text
                    color={theme.colors.gray300}
                    weight={theme.fonts.weight.medium}
                  >
                    {item.userName}
                  </Text>
                  <Div
                    width="32px"
                    height="32px"
                    radius="50px"
                    background={theme.colors.green}
                    align="center"
                    justify="center"
                  >
                    <SwapOutlined
                      style={{ cursor: "pointer", color: theme.colors.white }}
                      onClick={() => handleSearch(item)}
                    />
                  </Div>
                </Row>
                <Row align="center" gap="6px" m=" 0 0 5px 0" width="250px">
                  <EmailFillIcon
                    width="24px"
                    height="24px"
                    fill={theme.colors.green}
                    style={{ minWidth: "24px" }}
                  />
                  <Tooltip
                    title={item.userEmail}
                    color={theme.colors.white}
                    overlayInnerStyle={{
                      color: theme.colors.gray300,
                      fontFamily: theme.fonts.family,
                      fontSize: theme.fonts.size.sm,
                    }}
                    overlayStyle={{
                      maxWidth: "none",
                    }}
                  >
                    <Text
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.userEmail}
                    </Text>
                  </Tooltip>
                </Row>
                <Row gap="4px">
                  <Text
                    color={theme.colors.gray200}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.xs}
                  >
                    {t("requestDate")}
                  </Text>
                  <Text color={theme.colors.gray200} size={theme.fonts.size.xs}>
                    {dateFormat(item.requestDateTime)}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
      </Row>
    </>
  );
};

PendingRequest.propTypes = {
  setPreSelectedCompany: PropTypes.func,
  setShowModalAssociateComapany: PropTypes.func,
  setSelectedProjectReq: PropTypes.func,
};
