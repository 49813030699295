import React from "react";
import PropTypes from 'prop-types';
import { Modal } from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Text } from "../../../styles/Common";

export const ModalUpdate = ({ showModal, handleCloseModal, handleUpdate }) => {
  const { t } = useTranslation("strategies");

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        align="-webkit-center"
        padding={"22px 50px 0px 50px"}
        title={t("modalUpdate.title")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Text
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
          style={{ textAlign: "center" }}
        >
          {t("modalUpdate.message")}
        </Text>
      </Modal.Body>
      <Modal.Footer justifyContent="center" align="center" gap="16px">
        <Button
          width={"190px"}
          variant={"outlined"}
          onClick={() => handleUpdate(false)}
        >
          {t("modalUpdate.save")}
        </Button>
        <Button
          width={"190px"}
          onClick={() => handleUpdate(true)}
        >
          {t("modalUpdate.saveAndUpdate")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

ModalUpdate.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleUpdate: PropTypes.func,
};
