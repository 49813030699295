import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { Div } from "../../../styles/Common";

export const ModalImage = ({
  showModal, 
  handleCloseModal,
  financialImg,
  indicative,
}) => {
  return (
    <Modal 
      open={showModal} 
      onCancel={handleCloseModal}
      width={"1350px"}
      height={"600px"}
    >
      <Div
        margin="28px 0 32px 0"
        width="100%"
        justify="center"
        style={{ position: 'relative' }}
      >
        <img 
          alt="financialImg" 
          src={financialImg} 
          style={{ 
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: indicative === 1 ? `translate(-50%, -30%) scale(1.15)` : `translate(-50%, -37%) scale(1.15)`,
            clipPath: indicative === 1 ? 'inset(35% 3% 12% 5%)' : 'inset(40% 6% 9% 6%)',
          }} 
        />
      </Div>
    </Modal>
  )
};

ModalImage.propTypes = {
  showModal: PropTypes.bool,
  financialImg: PropTypes.string,
  indicative: PropTypes.number,
  handleCloseModal: PropTypes.func,
};