import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useAssignPendingUser = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put(`/user/assign-orphan-user`, formData);
  });
  return mutation;
};
export default useAssignPendingUser;
