import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../../styles/Common";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "../../../../components/RichTextArea";

export const Other = ({
  strategyData,
  tabActiveKey,
  handleChangeRichText,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  return (
    <>
      <Row m=" 33.5px 0 0 0">
        <RichTextArea
          label={t("other")}
          id="other"
          width="100%"
          height="138px"
          value={strategyData[Number(tabActiveKey) - 1].other}
          onChange={(value) =>
            handleChangeRichText(value, "other")
          }
        />
      </Row>
    </>
  );
};

Other.propTypes = {
  strategyData: PropTypes.array,
  tabActiveKey: PropTypes.string,
  handleChangeRichText: PropTypes.func,
};
