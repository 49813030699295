import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text, Div, Col, Row } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import { Radio } from "../../../../components/Radio";
import { Link } from "../../../../components/Link";
import useDiscardProject from "../../hooks/useDiscardProject";
import { validateDiscartProject } from "../../../../utilities/validations";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { TextArea } from "../../../../components/TextArea";

const ModalDiscardProject = ({
  showModal,
  handleCloseModal,
  selectedProject,
  instanceType,
  selectedProjectId,
}) => {
  const { t } = useTranslation("projects");
  const initialValues = {
    reason: null,
    comment: "",
  };
  const discardProject = useDiscardProject();
  const queryClient = useQueryClient();
  const [form, setForm] = useState(initialValues);
  const errorInitialValues = {
    reason: { error: false, message: "" },
    comment: { error: false, message: "" },
  };
  const [errorForm, setErrorForm] = useState(errorInitialValues);

  const saleOptions = [
    { name: t("modalDiscardProject.price"), placeholder: null, value: "Price" },
    { name: t("modalDiscardProject.size"), placeholder: null, value: "Size" },
    {
      name: t("modalDiscardProject.date"),
      placeholder: null,
      value: "RtB or CoD date",
    },
    {
      name: t("modalDiscardProject.information"),
      placeholder: t("modalDiscardProject.otherInfo"),
      value: "Lack of information",
    },
    {
      name: t("modalDiscardProject.others"),
      placeholder: t("modalDiscardProject.discardReason"),
      value: "Others",
    },
  ];

  const financingOptions = [
    {
      name: t("modalDiscardProject.saleEnergy"),
      placeholder: null,
      value: "Energy sales scheme",
    },
    {
      name: t("modalDiscardProject.creditMargin"),
      placeholder: null,
      value: "credit margin",
    },
    {
      name: t("modalDiscardProject.statusProject"),
      placeholder: null,
      value: "Project progress status",
    },
    {
      name: t("modalDiscardProject.size"),
      placeholder: null,
      value: "Size",
    },
    {
      name: t("modalDiscardProject.others"),
      placeholder: t("modalDiscardProject.discardReason"),
      value: "Others",
    },
  ];

  const options = instanceType === "sale" ? saleOptions : financingOptions;

  const handleRadio = (event) => {
    const { value, name } = event.target;

    setErrorForm(errorInitialValues);
    const newForm = { ...form };
    newForm[name] = value;
    newForm.comment = "";
    setForm(newForm);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = { ...errorForm };
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newForm = { ...form };
    newForm[id] = value;
    setForm(newForm);
  };

  const handleDiscardProject = () => {
    const validation = validateDiscartProject.validate(form, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = { ...errorForm };
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message = t(
          "modalDiscardProject.field"
        );
      });
      setErrorForm(newErrorForm);
    } else {
      discardProject.reset();
      discardProject.mutate(
        {
          projectId: selectedProjectId,
          reason: form.reason,
          description: form.comment,
        },
        {
          onSuccess: () => {
            toast.success(t("modalDiscardProject.discardSuccess"));
            setForm(initialValues);
            setErrorForm(errorInitialValues);
            queryClient.invalidateQueries("getProjects");
            handleCloseModal();
          },
          onError: (err) => {
            toast.error(err.response.data.result.code);
          },
        }
      );
    }
  };
  return (
    <Modal
      open={showModal}
      onCancel={() => {
        setForm(initialValues);
        setErrorForm(errorInitialValues);
        handleCloseModal();
      }}
    >
      <Modal.Body
        margin="32px 0 0 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col>
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h5}
            align="center"
            mb="32px"
            color={theme.colors.blue}
          >
            {t("modalDiscardProject.discardReasonProject")}{" "}
            <span
              style={{
                color: theme.colors.green,
              }}
            >
              {selectedProject}
            </span>
            ?
          </Text>
          <Text color={theme.colors.gray500} align="center" mb="28px">
            {t("modalDiscardProject.alter")}{" "}
            <span style={{ fontWeight: theme.fonts.weight.medium }}>
              {t("modalDiscardProject.investor")}
            </span>
          </Text>
          <Col align="left" m="0 0 15px 0">
            {options.map((option, index) => (
              <Div
                gap="5px"
                width="398px"
                height="auto"
                align="left"
                p="16px 0px 16px 23px"
                radius="11px"
                m="0 0 4px 0"
                direction="column"
                background={theme.colors.green100}
                key={index}
              >
                <Row align="center" gap="5px">
                  <Radio
                    onChange={handleRadio}
                    name="reason"
                    value={option.value}
                    checked={form.reason === option.value}
                  />
                  <Text color={theme.colors.gray300}>{option.name}</Text>
                </Row>
                {(form.reason === "Lack of information" ||
                  form.reason === "Others") &&
                  form.reason === option.value && (
                    <TextArea
                      width="352px"
                      onChange={handleChange}
                      id="comment"
                      rows={5}
                      value={form.comment}
                      placeholder={option.placeholder}
                      error={errorForm.comment.error}
                      helper={errorForm.comment.message}
                    />
                  )}
              </Div>
            ))}
          </Col>

          <Col align="center">
            {errorForm.reason.error && (
              <Text color={theme.colors.error} size={theme.fonts.size.sm}>
                {t("modalDiscardProject.selectOneOption")}
              </Text>
            )}
            <Button
              width="268px"
              height="45px"
              m="0 0 22px 0"
              onClick={handleDiscardProject}
              loading={discardProject.isLoading}
            >
              {t("modalDiscardProject.send")}
            </Button>
            <Link
              onClick={() => {
                setForm(initialValues);
                setErrorForm(errorInitialValues);
                handleCloseModal();
              }}
            >
              {t("modalDiscardProject.cancel")}
            </Link>
          </Col>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalDiscardProject.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  selectedProject: PropTypes.string,
  instanceType: PropTypes.string,
  selectedProjectId: PropTypes.string,
};

export default ModalDiscardProject;
