import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Text, Row, Div } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { UserAdd } from "../../../../assets/icons";
import { UserCheckIcon } from "../../../../assets/icons";
import { Button } from "../../../../components/Button";
import ReactLoading from "react-loading";
import useCloseTransaction from "../../hooks/useCloseTransaction";
import { Row as AntdRow, Col } from "antd";

const ModalDistribution = ({
  showModal,
  handleCloseModal,
  selectedStrategy,
  setSelectedStrategy,
  handleOpenInvitationDrawer,
  data,
  isLoadingStrategies,
  handleOpenInvitation,
  projectId,
}) => {
  const { t } = useTranslation("projects");

  const closeTransaction = useCloseTransaction();
  const queryClient = useQueryClient();

  const changeStrategy = (strategy) => {
    setSelectedStrategy(strategy);
  };

  const handleCloseTransaction = () => {
    closeTransaction.reset();
    closeTransaction.mutate(
      {
        projectId: projectId,
        strategyId: selectedStrategy?.value,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getProjectsManager"]);
          toast.success(t("modalDistribution.closeTransaction"));
          handleCloseModal();
        },
        onError: (err) => {
          toast.error(
            err.response.data.result.code.message ||
              err.response.data.result.code
          );
        },
      }
    );
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        align="-webkit-left"
        padding={"0px 50px 0px 35px"}
        title={t("modalDistribution.select")}
        sizeTitle={theme.fonts.size.h5i}
        weightTitle={theme.fonts.weight.medium}
      />
      <Modal.Body
        margin="28px 0 0 0"
        padding="0 22px"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Div direction="column" width="100%">
          {isLoadingStrategies ? (
            <Div width="100%" height="100%" justify="center" align="center">
              <ReactLoading color={theme.colors.green} />
            </Div>
          ) : data.length > 0 ? (
            <AntdRow
              gutter={[16, 16]}
              style={{ 
                width: "100%",
                margin: selectedStrategy === null ? "0" : "0 0 33px 0",
                display: "flex",
                justifyContent: data.length % 2 === 1 ? "center" : "flex-start"
              }}
            >
              {data.map((item) => (
                <Col md={12} key={item.value}>
                  <Div
                    width="183px"
                    height="47px"
                    background={
                      selectedStrategy?.value === item?.value
                        ? theme.colors.green100
                        : theme.colors.white
                    }
                    border="1px solid"
                    borderColor={theme.colors.green}
                    radius="8px"
                    align="center"
                    justify="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => changeStrategy(item)}
                  >
                    <Text color={theme.colors.green}>{item.label}</Text>
                  </Div>
                </Col>
              ))}
            </AntdRow>
          ) : (
            <Text>{t("modalDistribution.noStrategy")}</Text>
          )}

          {selectedStrategy !== null && (
            <>
              <Row m="0 0 33px 0">
                <Text
                  color={theme.colors.blue}
                  size={theme.fonts.size.h5i}
                  weight={theme.fonts.weight.medium}
                >
                  {t("modalDistribution.do")}
                </Text>
              </Row>
              <Row gap="17px" m="0 0 33px 0">
                <Div
                  width="193px"
                  height="109px"
                  background={theme.colors.white}
                  border="1px solid"
                  borderColor={theme.colors.gray100}
                  radius="8px"
                  align="center"
                  justify="center"
                  direction="column"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenInvitationDrawer}
                >
                  <Div
                    width="50px"
                    m="0 0 8px"
                    height="50px"
                    radius="50px"
                    background={theme.colors.green100}
                    align="center"
                    justify="center"
                  >
                    <UserAdd width="32px" height="32px" />
                  </Div>
                  <Text color={theme.colors.gray300}>
                    {t("modalDistribution.invite")}
                  </Text>
                </Div>
                <Div
                  width="193px"
                  height="109px"
                  background={theme.colors.white}
                  border="1px solid"
                  borderColor={theme.colors.gray100}
                  radius="8px"
                  align="center"
                  justify="center"
                  direction="column"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenInvitation}
                >
                  <Div
                    width="50px"
                    m="0 0 8px"
                    height="50px"
                    radius="50px"
                    background={theme.colors.green100}
                    align="center"
                    justify="center"
                  >
                    <UserCheckIcon
                      width="32px"
                      height="32px"
                      fill={theme.colors.green}
                    />
                  </Div>
                  <Text color={theme.colors.gray300}>
                    {t("modalDistribution.see")}
                  </Text>
                </Div>
              </Row>
              <Row justify="center">
                <Button
                  width="268px"
                  height="45px"
                  onClick={handleCloseTransaction}
                  loading={closeTransaction.isLoading}
                >
                  {t("modalDistribution.close")}
                </Button>
              </Row>
            </>
          )}  
        </Div>
      </Modal.Body>
    </Modal>
  );
};

ModalDistribution.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  selectedStrategy: PropTypes.object,
  setSelectedStrategy: PropTypes.func,
  handleOpenInvitationDrawer: PropTypes.func,
  data: PropTypes.array,
  isLoadingStrategies: PropTypes.bool,
  handleOpenInvitation: PropTypes.func,
  projectId: PropTypes.string,
};

export default ModalDistribution;
