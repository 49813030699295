import React from "react";
import PropTypes from "prop-types";
import { StyledPagination } from "./styles";

export const Pagination = ({
  total,
  onChange,
  current,
  pageSize,
  showSizeChanger,
  defaultPageSize,
  ...rest
}) => {
  return (
    <StyledPagination
      total={total}
      onChange={onChange}
      current={current + 1}
      pageSize={pageSize}
      showSizeChanger={showSizeChanger}
      defaultPageSize={defaultPageSize}
      {...rest}
    />
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  onChange: PropTypes.func,
  showSizeChanger: PropTypes.bool,
  defaultPageSize: PropTypes.number,
};
