import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { theme } from "../../../styles/theme";
import { Text, Row, Div, Col } from "../../../styles/Common";
import { Link } from "../../../components/Link";
import { Upload } from "antd";
import toast from "react-hot-toast";
import { getUserInfo } from "../../../utilities/helpers";
import { Collapse } from "../../../components/Collapse";
import { SeniorFinancing } from "./SeniorFinancing";
import {
  UploadIcon,
  UserIcon,
  PlantIcon,
  SaleIcon,
  DeleteIcon,
  EyeIcon,
  SunIcon,
  WindyIcon,
  BateryIcon,
} from "../../../assets/icons";
import useGetSizingParameters from "../hooks/useGetSizingParameters";
import useFinancingSource from "../hooks/useFinancingSource";
import ModalSuccess from "../../NewProject/components/ModalSuccess";
import { useNavigate } from "react-router-dom";
import useUpdateUser from "../hooks/useUpdateUser";
import useUpdateFinancingSource from "../hooks/useUpdateFinancingSource";
import Checkbox from "../../../components/Checkbox";

export const InvestorCompanyData = ({
  userData,
  setUserData,
  handleUpdate,
  loading,
  imageUrl,
  isLoadingImg,
}) => {
  const { t } = useTranslation("myAccount");
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const updateUser = useUpdateUser();
  const financingSource = useFinancingSource();
  const updateFinancingSource = useUpdateFinancingSource();
  const getSizingParameters = useGetSizingParameters(userData?.companyId);
  const { data, isSuccess } = getSizingParameters;
  const [form, setForm] = useState({
    legalTenor: null, //shortTenor
    debtSizing: null, //tenor
    amortizationProfile: null, //debtPayment
    upFrontFee: null, //shortFinancingOpeningFee
    interest: null, //interest
    targetCoupon: null, //shortFinancingSpread
    maxiumLeverageOver: null, //maxLeverageOverCAPEX
    merchant: null, //DSCRForMerchant
    nonCallPeriod: null, //nonCallPeriod
    switchSwap: null, //swapHedge
    swapPercentage: null, // hedge
    productionPriceCurve: null, //priceCurveScenario
    productionScenario: null, // productionScenario
    switchCashSweepActivation: null, //cashSweepActivation
    cashSweep: null, //CSRatioPeriod1
  });
  const [checkOthersType, setCheckOthersType] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      setForm({
        legalTenor: data?.data?.data?.shortTenor,
        debtSizing: data?.data?.data?.tenor,
        amortizationProfile: data?.data?.data?.debtPayment,
        upFrontFee: data?.data?.data?.shortFinancingOpeningFee,
        interest: data?.data?.data?.interest,
        targetCoupon: data?.data?.data?.shortFinancingSpread,
        maxiumLeverageOver: data?.data?.data?.maxLeverageOverCAPEX,
        merchant: data?.data?.data?.DSCRForMerchant,
        nonCallPeriod: data?.data?.data?.nonCallPeriod,
        switchSwap: data?.data?.data?.swapHedge,
        swapPercentage: data?.data?.data?.hedge,
        productionPriceCurve: data?.data?.data?.priceCurveScenario,
        productionScenario: data?.data?.data?.productionScenario,
        switchCashSweepActivation: data?.data?.data?.cashSweepActivation,
        cashSweep: data?.data?.data?.CSRatioPeriod1,
      });
    }
  }, [data, isSuccess]);

  const [activeKey, setActiveKey] = useState(["0"]);
  const [document, setDocument] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    navigate("/projects");
  };

  const maxSize = 500000000;
  const handleUpload = (info) => {
    const { file } = info;
    if (file.type === "application/pdf" && file.size < maxSize) {
      setDocument(file);
    } else {
      toast.dismiss();
      toast.error(t("notPermitted"));
    }
  };

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const handleDelete = () => {
    setDocument(null);
  };

  const items = [
    {
      key: "1",
      header: t("senior"),
      panel: <SeniorFinancing form={form} setForm={setForm} />,
    },
    {
      key: "2",
      header: t("junior"),
      panel: "Panel Junior Financing",
    },
  ];

  const phaseFilter = [
    { label: "Pre RtB", value: 0 },
    { label: "RtB", value: 1 },
    { label: "CoD", value: 2 },
  ];

  const typeFilter = [
    {
      label: "solar",
      value: "solar",
      icon: <SunIcon fill={theme.colors.gray200} />,
    },
    {
      label: "eolic",
      value: "eolic",
      icon: <WindyIcon fill={theme.colors.gray200} />,
    },
    {
      label: "storage",
      value: "storage",
      icon: <BateryIcon fill={theme.colors.gray200} />,
    },
  ];
  const otherTypeFilter = [
    {
      label: "biogas",
      value: "biogas",
    },
    {
      label: "hydrogen",
      value: "hydrogen",
    },
  ];

  const sizeFilter = [
    { label: "- 10MW", value: "lessThanTen" },
    { label: "10MW - 50MW", value: "fromTenToFifty" },
    { label: "+ 50MW", value: "moreThanFifty" },
  ];

  const handleChangeCheckbox = (group, id) => (e) => {
    const { checked } = e.target;
    const label = id;
    setUserData((prevValues) => {
      const newValues = [...prevValues[group]];

      if (checked) {
        newValues.push(label);
      } else {
        const index = newValues.indexOf(label);
        if (index > -1) {
          newValues.splice(index, 1);
        }
      }

      return {
        ...prevValues,
        [group]: newValues,
      };
    });
  };

  const handleSave = async () => {
    toast.remove();
    const formData = {
      type: 1,
      investorId: userInfo.companyId,
      shortTenor: form.legalTenor,
      tenor: form.debtSizing,
      debtPayment: form.amortizationProfile,
      shortFinancingOpeningFee: form.upFrontFee,
      interest: form.interest,
      shortFinancingSpread: form.targetCoupon,
      maxLeverageOverCAPEX: form.maxiumLeverageOver,
      DSCRForMerchant: form.merchant,
      nonCallPeriod: form.nonCallPeriod,
      swapHedge: form.switchSwap,
      hedge: form.swapPercentage,
      productionScenario: form.productionScenario,
      priceCurveScenario: form.productionPriceCurve,
      cashSweepActivation: form.switchCashSweepActivation,
      CSRatioPeriod1: form.cashSweep,
    };

    financingSource.reset();
    updateFinancingSource.reset();

    const projectPreferences = {
      projectStage: userData?.phase,
      projectType: userData?.type,
      projectSize: userData?.size,
      preferenceViewed: true,
    };
    const updatedUserData = {
      ...userData,
      company: {
        ...userData.company,
        projectPreferences: projectPreferences,
      },
    };
    delete updatedUserData.createdAt;
    delete updatedUserData.phase;
    delete updatedUserData.type;
    delete updatedUserData.size;

    const mutateWithPromise = (mutateFunction, variables) => {
      return new Promise((resolve, reject) => {
        mutateFunction(variables, {
          onSuccess: (result) => resolve(result),
          onError: (err) => reject(err),
        });
      });
    };

    try {
      const financingResult = await mutateWithPromise(
        data?.data?.data?._id
          ? updateFinancingSource.mutate
          : financingSource.mutate,
        data?.data?.data?._id
          ? { id: data?.data?.data?._id, formData }
          : formData
      );

      const userUpdateResult = await mutateWithPromise(updateUser.mutate, {
        ...updatedUserData,
      });

      if (financingResult && userUpdateResult) {
        setShowModal(true);
      } else {
        toast.error(t("unable"));
      }
    } catch (err) {
      toast.error(
        `${
          err.response.data.result.errorMessage?.message ||
          err.response.data.result.code
        }`
      );
    }
  };

  const financingInvestor = userInfo?.investType === "dInv" || userInfo?.investType === "both";

  return (
    <Card
      width="80%"
      height="551px"
      padding="27px 44px 42px 41px"
      margin="0 20px 20px 0"
      style={{ overflow: " auto" }}
    >
      <ModalSuccess
        showModal={showModal}
        page={"myAccount"}
        handleCloseModal={handleClose}
      />
      <Row m="0 0 24px 0" align="center" justify="space-between">
        <Text
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h5}
        >
          {t("companyData")}
        </Text>
        <Div style={{ cursor: "pointer" }} gap="4px">
          <UploadIcon fill={theme.colors.green} width="24px" height="24px" />
          <Text color={theme.colors.green}>{t("download")}</Text>
        </Div>
      </Row>

      <Div m=" 0 0 24px 0">
        {isLoadingImg ? (
          <Div width="264px" height="70px" justify="center" align="center">
            <ReactLoading color={theme.colors.green} />
          </Div>
        ) : (
          imageUrl && (
            <img
              alt="icon"
              src={imageUrl}
              width="264px"
              height={"70px"}
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          )
        )}
      </Div>

      <Row gap="31px">
        <Input
          label={t("companyName")}
          sizeLabel={theme.fonts.size.default}
          value={userData.company.name}
          width={"100%"}
          disabled
          style={{ marginBottom: "32px" }}
        />
        <Input
          label={t("phoneNumber")}
          sizeLabel={theme.fonts.size.default}
          value={userData?.phone}
          width={"100%"}
          style={{ marginBottom: "32px" }}
        />
      </Row>
      <Row gap="17px" m="0 0 32px 0" justify="space-between">
        <Div
          background={theme.colors.blue}
          width="204px"
          height="85px"
          radius="15px"
          align="center"
          justify="center"
          gap="7px"
        >
          <Col>
            <Div
              width="50px"
              height="50px"
              radius="10px"
              background={theme.colors.white100}
              align="center"
              justify="center"
            >
              <UserIcon width="32px" height="32px" />
            </Div>
          </Col>
          <Col align="left">
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.smi}
            >
              {t("numberUsers")}
            </Text>
            <Text color={theme.colors.green100} size={theme.fonts.size.smi}>
              {userData?.company?.totalUsers} {t("users")}
            </Text>
          </Col>
        </Div>
        <Div
          background={theme.colors.gray200}
          width="204px"
          height="85px"
          radius="15px"
          align="center"
          justify="center"
          gap="7px"
        >
          <Col>
            <Div
              width="50px"
              height="50px"
              radius="10px"
              background={theme.colors.white100}
              align="center"
              justify="center"
            >
              <PlantIcon width="32px" height="32px" />
            </Div>
          </Col>
          <Col align="left">
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.smi}
            >
              {t("activeProjects")}
            </Text>
            <Text color={theme.colors.green100} size={theme.fonts.size.smi}>
              {userData?.company?.totalProjects} {t("projectsNumber")}
            </Text>
          </Col>
        </Div>
        <Div
          background={theme.colors.green}
          width="204px"
          height="85px"
          radius="15px"
          align="center"
          justify="center"
          gap="7px"
        >
          <Col>
            <Div
              width="50px"
              height="50px"
              radius="10px"
              background={theme.colors.white100}
              align="center"
              justify="center"
            >
              <SaleIcon width="32px" height="32px" />
            </Div>
          </Col>
          <Col align="left">
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.smi}
            >
              {t("investments")}
            </Text>
            <Text color={theme.colors.green100} size={theme.fonts.size.smi}>
              {userData?.company?.totalInvestor} {t("investmentsNumber")}
            </Text>
          </Col>
        </Div>
      </Row>
      {document === null ? (
        <Upload multiple={false} showUploadList={false} onChange={handleUpload}>
          <Div m="0 0 36px 0">
            <Button variant={"outlined"} width="325px">
              <Div align="center" justify="center" gap="4px">
                <UploadIcon
                  fill={theme.colors.green}
                  width="24px"
                  height="24px"
                />
                {t("signed")}
              </Div>
            </Button>
          </Div>
        </Upload>
      ) : (
        <Div
          background={theme.colors.green100}
          radius="7px"
          align="center"
          p="10px 18px 10px 12px"
          border="1px solid"
          borderColor={theme.colors.gray100}
          justify="space-between"
          width="425px"
          m="0 0 22px 0"
        >
          <Col align="left" width="100px">
            <Text mb="7px" color={theme.colors.gray300}>
              {document?.name}
            </Text>
            <Text size={theme.fonts.size.sm} color={theme.colors.green}>
              {document?.size
                ? `${(document.size / (1024 * 1024)).toFixed(2)} Mb`
                : "--"}
            </Text>
          </Col>
          <Row gap="13px">
            <Div
              radius="50%"
              width="38px"
              height="38px"
              justify="center"
              align="center"
              m="0 0 8px 0"
            >
              <Link>
                <DeleteIcon
                  fill={theme.colors.gray200}
                  onClick={handleDelete}
                />
              </Link>
            </Div>
            <Div
              radius="50%"
              width="38px"
              height="38px"
              justify="center"
              align="center"
              m="0 0 8px 0"
            >
              <Link>
                <EyeIcon fill={theme.colors.gray200} />
              </Link>
            </Div>
          </Row>
        </Div>
      )}

      <Text
        size={theme.fonts.size.h5}
        color={theme.colors.blue}
        weight={theme.fonts.weight.medium}
        mb="14px"
      >
        {t("updatePreferences")}
      </Text>
      <Col align="left" m="0 0 20px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          mb="10px"
        >
          {t("state")}
        </Text>
        <Checkbox.Group direction="row">
          {phaseFilter.map((state, index) => (
            <Checkbox
              key={index}
              label={state.label}
              style={{ width: "152px" }}
              onChange={handleChangeCheckbox("phase", state.value)}
              checked={userData.phase.includes(state.value)}
            />
          ))}
        </Checkbox.Group>
      </Col>

      <Col align="left" m="0 0 10px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          mb="10px"
        >
          {t("technology")}
        </Text>
        <Checkbox.Group direction="row">
          {typeFilter.map((type, index) => (
            <Checkbox
              key={index}
              label={
                <Row align="center">
                  {type.icon}
                  {t(`${type.label}`)}
                </Row>
              }
              style={{ width: "152px" }}
              onChange={handleChangeCheckbox("type", type.value)}
              checked={userData.type.includes(type.value)}
            />
          ))}
        </Checkbox.Group>
      </Col>

      <Col m="0 0 20px 0" gap="13px">
        <Checkbox
          label={t("others")}
          onChange={() => setCheckOthersType(!checkOthersType)}
          checked={checkOthersType}
        />
        {checkOthersType && (
          <Checkbox.Group
            direction="row"
            style={{
              backgroundColor: theme.colors.green100,
              borderRadius: "11px",
              padding: "18px 22px",
            }}
          >
            {otherTypeFilter.map((state, index) => (
              <Checkbox
                key={index}
                label={t(`${state.label}`)}
                style={{ width: "170px" }}
                onChange={handleChangeCheckbox("type", state.value)}
                checked={userData.type.includes(state.value)}
              />
            ))}
          </Checkbox.Group>
        )}
      </Col>

      <Col align="left" m="0 0 43px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          mb="10px"
        >
          {t("size")}
        </Text>
        <Checkbox.Group direction="row">
          {sizeFilter.map((size, index) => (
            <Checkbox
              key={index}
              label={t(size.label)}
              style={{ width: "152px" }}
              onChange={handleChangeCheckbox("size", size.value)}
              checked={userData.size.includes(size.value)}
            />
          ))}
        </Checkbox.Group>
      </Col>
      {financingInvestor && (
        <>
          <Text
            size={theme.fonts.size.h5}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            mb="24px"
          >
            {t("dimensioningParameters")}
          </Text>
          <Collapse
            activeKey={activeKey}
            handlePanelChange={handlePanelChange}
            panels={items}
            marginBottom={"24px"}
          />
        </>
      )}
      <Row>
        {financingInvestor ? (
          <Button
            onClick={handleSave}
            loading={
              financingSource.isLoading || updateFinancingSource.isLoading
            }
            width="192px"
          >
            {t("updateInformation")}
          </Button>
        ) : (
          <Button onClick={handleUpdate} loading={loading}>
            {t("saveChanges")}
          </Button>
        )}
      </Row>
    </Card>
  );
};

InvestorCompanyData.propTypes = {
  countriesOptions: PropTypes.array,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  handleSelect: PropTypes.func,
  country: PropTypes.string,
  handleChangeCountry: PropTypes.func,
  handleChangeCompany: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  loading: PropTypes.bool,
  imageUrl: PropTypes.string,
  isLoadingImg: PropTypes.bool,
};
