import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";

const ModalDeepeningInvestment = ({ 
  showModal, 
  handleCloseModal, 
  handleRequestNDA, 
  handleContact,
  loadingNda,
  loadingContact,
}) => {
  const { t } = useTranslation("investorProjectDetail");

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        align="-webkit-center"
        padding={"22px 50px 0px 50px"}
        title={t("deepeningInvestmentModal.title")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Text size={theme.fonts.size.default} color={theme.colors.gray500} style={{ textAlign: 'center' }}>
          {t("deepeningInvestmentModal.message")}
        </Text>
      </Modal.Body>
      <Modal.Footer direction="column" gap="16px" justify="center" align="center" >
        <Button
          width="281px"
          height="45px"
          m="0 0 3px 0"
          onClick={handleRequestNDA}
          isLoading={loadingNda}
        >
          {t("deepeningInvestmentModal.ndaButton")}
        </Button>
        <Button
          width="281px"
          height="45px"
          m="0 0 3px 0"
          onClick={handleContact}
          isLoading={loadingContact}
        >
          {t("deepeningInvestmentModal.contactButton")}
        </Button>
        <Link onClick={handleCloseModal}>
          {t("cancel")}
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

ModalDeepeningInvestment.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleRequestNDA: PropTypes.func,
  handleContact: PropTypes.func,
  loadingNda: PropTypes.bool,
  loadingContact: PropTypes.bool,
};

export default ModalDeepeningInvestment;