import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { Div, Text } from "../../../../styles/Common";
import Drawer from "../../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { theme } from "../../../../styles/theme";
import { AlertIcon } from "../../../../assets/icons";
import { WrapperIcon } from "./styles";
import messagesImg from "../../../../assets/images/messages.svg";
import { TextArea } from "../../../../components/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { setIncidences } from "../../../../core/store/projects";
import { DrawerAllIncidences } from "../DrawerAll";
import useCloseIncidence from "../../hooks/useCloseIncidence";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import useCreateComments from "../../hooks/useCreateComment";
import { getUserInfo } from "../../../../utilities/helpers";

export const DrawerAlert = ({ 
  openDrawer, 
  handleClose, 
  field, 
  fieldId, 
  incidencesList, 
  plantId, 
  plantName, 
  allIncidences,
  source,
  plantsSource
}) => {
  const { t } = useTranslation("newProject");
  const role = getUserInfo().role[0];
  const isManager = role === 'Manager';
  const queryClient = useQueryClient();
  const closeIncidence = useCloseIncidence();
  const createComment = useCreateComments();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");
  const { incidences } = useSelector((state) => state.projects);
  const [comment, setComment] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [incidencesInfo, setIncidencesInfo] = useState([]);

    const transformedIncidents = incidencesInfo?.reduce((acc, item) => {
    const { projectField, ...rest } = item;
    if (!acc[projectField]) {
        acc[projectField] = [];
    }
    acc[projectField].push(rest);
    return acc;
  }, {}) || {};
  
  const incidence = Object.keys(transformedIncidents).length > 0 && transformedIncidents[fieldId]
    ? transformedIncidents[fieldId].slice(-1)[0] 
    : {};

  useEffect(() => {
    if (incidences.length > 0) {
      setIncidencesInfo(incidences);
    } else {
      setIncidencesInfo(incidencesList);
    }
  }, [incidences, incidencesList]);

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleAddIncidence = () => {
    if (incidence?.incidenceId) {
      if (incidence.status === 'Open') {
        createComment.reset();
        createComment.mutate({
          incidenceId: incidence.incidenceId,
          comment: comment,
        }, {
          onSuccess: () => {
            setComment("");
            queryClient.invalidateQueries(["incidencesInfo", projectId]);
          }
        });
      } else {
        const newIncidence = {
          projectId: projectId,
          projectField: fieldId,
          fieldName: field,
          comment: comment,
          incidenceId: incidence.incidenceId,
          ...(plantId ? { plantId: plantId } : {}),
          ...(plantName ? { plantName: plantName } : {}),
        };
        setComment("");
        dispatch(setIncidences([...incidencesInfo, newIncidence]));
      }
    } else {
      const newIncidence = {
        projectId: projectId,
        projectField: fieldId,
        fieldName: field,
        comment: comment,
        ...(plantId ? { plantId: plantId } : {}),
        ...(plantName ? { plantName: plantName } : {}),
      };
      setComment("");
      dispatch(setIncidences([...incidencesInfo, newIncidence]));
    }
  };

  const handleRespondIncidence = () => {
    createComment.reset();
    createComment.mutate({
      incidenceId: incidence.incidenceId,
      comment: comment,
    }, {
      onSuccess: () => {
        setComment("");
        queryClient.invalidateQueries(["incidencesInfo", projectId]);
      }
    });
  };

  const handleSolveIncidence = () => {
    closeIncidence.reset();
    closeIncidence.mutate(incidence?.incidenceId, {
      onSuccess: () => {
        toast.success(t("alertRFA.successSolve"));
        queryClient.invalidateQueries(["incidencesInfo", projectId]);
        handleClose();
      }, 
      onError: (err) => {
        err.response.data.result.code.message.map((message) =>
          toast.error(message)
        );
      },
    });
  };

  const handleCloseDrawer = () => {
    setShowAll(false);
  };

  const handleCloseDrawerAlert = () => {
    handleClose();
    setComment("");
  };

  return (
    <Drawer
      title={t("alertRFA.title")}
      open={openDrawer}
      onClose={handleCloseDrawerAlert}
    >
      <DrawerAllIncidences 
        openDrawer={showAll} 
        handleClose={handleCloseDrawer} 
        incidencesList={source === 'plants' ? allIncidences : incidencesList} 
        projectId={projectId}
      />
      <Div direction="column" height="100%" justify="space-between">
        <Div direction="column">
          <Text 
            color={theme.colors.gray500}
            mt="16px" 
            mb="32px"
          >
            {t("alertRFA.subtitle")}
          </Text>
          <Div align="center" gap="9px" >
            <WrapperIcon>
              <AlertIcon fill={theme.colors.yellow700} width={"24px"} height={"24px"} />
            </WrapperIcon>
            <Text 
              color={theme.colors.yellow700}
              style={{
                textAlign: "left",
              }}
            >
              {field}
            </Text>
          </Div>
          <Div justify="center" m="39px 0 0 0">
            {transformedIncidents[fieldId]?.length > 0 ? (
              <Div width="100%" direction="column" gap="16px">
                {transformedIncidents[fieldId].map((incidence, indx) => (
                  <Div 
                    key={indx} 
                    width="70%" 
                    direction="column" 
                    gap="8px"
                    style={{
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "23px",
                      padding: "16px",
                      backgroundColor: incidence.userRole === 'Developer' ? theme.colors.green50 : "transparent",
                      alignSelf: incidence.userRole === 'Developer' ? "flex-end" : "flex-start",
                    }}
                  >
                    {incidence.plantId && (
                      <Text
                        color={theme.colors.blue}
                        weight={theme.fonts.weight.light}
                        size={theme.fonts.size.xs}
                      >
                        {t("alertRFA.plantName")}: {incidence.plantName || ''}
                      </Text>
                    )}
                    <Text style={{ textAlign: incidence.userRole === 'Developer' ? "right" : "left" }}>{incidence.comment}</Text>
                    {isManager && incidence.userRole !== 'Developer' && (
                      <Text 
                        color={theme.colors.gray500} 
                        size={theme.fonts.size.xs} 
                        weight={theme.fonts.weight.light}
                        align="right"
                      >
                        {incidence.user ? incidence.user : getUserInfo()?.name}
                      </Text>
                    )}
                  </Div>
                ))}
              </Div>
            ) : (
              <Div direction="column" width="100%" align="center" justify="center" gap="14px">
                <img alt="messages" src={messagesImg} style={{ opacity: 0.3 }} />
                <Text 
                  color={theme.colors.gray100}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.medium}
                  mb="37px"
                >
                  {t("alertRFA.noComments")}
                </Text>
              </Div>
            )}
          </Div>
        </Div>
        <Div direction="column" gap="16px" width="100%" align="center" m="22px 0 0 0">
          <TextArea
            label={t("alertRFA.comments")}
            width="100%"
            value={comment}
            onChange={handleChangeComment}
            rows={4}
            style={{
              marginBottom: "22px",
            }}
            disabled={incidence?.status === 'Closed' && !isManager || plantsSource}
          />
          {incidence?.status === 'Open' && isManager && (
            <Link onClick={handleSolveIncidence} disabled={closeIncidence.isLoading || plantsSource}>{t("alertRFA.solve")}</Link>
          )}
          <Button 
            width={"221px"} 
            onClick={isManager ? handleAddIncidence : handleRespondIncidence}
            disabled={incidence?.status === 'Closed' && !isManager || plantsSource}
          >
            {isManager ? t("alertRFA.addIncidence") : t("alertRFA.respondIncidence")}
          </Button>
          <Button 
            variant={"outlined"} 
            width={"221px"}
            onClick={() => {
              setShowAll(true)
              handleClose()
            }}
          >
            {t("alertRFA.seeIncidence")}
          </Button>
        </Div>
      </Div>
    </Drawer>
  )
};


DrawerAlert.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  field: PropTypes.string,
  fieldId: PropTypes.string,
  incidencesList: PropTypes.array,
  plantId: PropTypes.string,  
  plantName: PropTypes.string,
  allIncidences: PropTypes.array,
  source: PropTypes.string,
  plantsSource: PropTypes.bool,
};