import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetAnswersDeclaration = (projectId) => {
  const data = useQuery(["getAnswersDeclaration", projectId], () =>
    axiosClient.get(
      `/informational-declaration/answer?projectId=${projectId}`),
      { enabled: !!projectId }
  );

  return data;
};

export default useGetAnswersDeclaration;