import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { DeleteIcon } from "../../../../assets/icons";
import { Button } from "../../../../components/Button";
import useDeleteProject from "../../hooks/useDeleteProject";

const ModalDelete = ({
  showModal,
  handleCloseModal,
  projectId,
  projectName,
}) => {
  const { t } = useTranslation("projectTableManager");
  const queryClient = useQueryClient();
  const deleteProject = useDeleteProject();

  const handleDelete = () => {
    deleteProject.reset();
    deleteProject.mutate(projectId, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getProjectsManager"]);
        toast.success(t("modalDelete.deleteSuccess"));
        handleCloseModal();
      },
      onError: (err) => {
        toast.error(
          err.response.data.result.errorMessage.message ||
            err.response.data.result.code
        );
      },
    });
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        icon={
          <DeleteIcon
            fill={theme.colors.error}
            width={"50px"}
            height={"50px"}
          />
        }
        iconBackground={theme.colors.error100}
        iconBorderColor={theme.colors.error}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 50px 0px 50px"}
        title={t("modalDelete.sure")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" m="0 0 32px 0">
            <Text size={theme.fonts.size.default} color={theme.colors.gray500}>
              {
                t("modalDelete.deleteText", {
                  userName: projectName,
                }).split("{{projectName}}")[0]
              }
              <span style={{ color: theme.colors.green }}>{projectName}</span>
              {t("modalDelete.deleteText").split("{{projectName}}")[1]}
            </Text>
          </Row>
          <Row width="100%" gap="20px">
            <Button
              width={"190px"}
              onClick={handleCloseModal}
              variant={"outlined"}
            >
              {t("modalDelete.cancel")}
            </Button>
            <Button
              width={"190px"}
              onClick={handleDelete}
              loading={deleteProject.isLoading}
            >
              {t("modalDelete.deleteProject")}
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalDelete.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
};

export default ModalDelete;
