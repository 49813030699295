import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { theme } from "../../styles/theme";
import { Div, ModuleContainer, Row, Text } from "../../styles/Common";
import {
  ArrowLeftIcon,
  DeleteIcon,
  EyeIcon,
  SearchIcon,
} from "../../assets/icons";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Switch } from "../../components/Switch";
import { useTranslation } from "react-i18next";
import { dateFormat, downloadFile, getToken } from "../../utilities/helpers";
import { Link } from "../../components/Link";
import { Table } from "../../components/Table";
import useGetInvestingCompanies from "./hooks/useGetInvestingCompanies";
import { Skeleton } from "../../components/Skeleton";
import useDeleteInvestingCompany from "./hooks/useDeleteInvestingCompany";
import { Modal } from "../../components/Modal";
import deleteIcon from "../../assets/icons/Delete-error.svg";
import useSignTermsAndConditions from "./hooks/useSignTermsAndConditions";
import { axiosClient } from "../../core/services/axiosInstance";

export const SeeInvitations = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["seeInvitations", "common"]);
  const navigate = useNavigate();
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");
  const strategyId = new URLSearchParams(search).get("strategyId");
  const projectType = new URLSearchParams(search).get("type");
  const deleteInvestingCompany = useDeleteInvestingCompany();
  const sign = useSignTermsAndConditions();
  const [pages, setPages] = useState({ pageNumber: 1, pageSize: 10 });
  const [totalItems, setTotalItems] = useState(10);
  const [name, setName] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [fetchAction, setFetchAction] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [investorInvitationId, setInvestorInvitationId] = useState("");

  const [payload, setPayload] = useState({
    projectId: projectId,
    strategyId: strategyId,
    limit: pages.pageSize,
    offset: pages.pageNumber,
    name: name,
  });
  const { data, isSuccess, isLoading } = useGetInvestingCompanies(payload);

  useEffect(() => {
    if (fetchAction) {
      setPayload({
        projectId: projectId,
        strategyId: strategyId,
        limit: pages.pageSize,
        offset: pages.pageNumber,
        name: name,
      });
    }
  }, [pages, name, fetchAction, projectId, strategyId]);

  const handleBack = () => {
    navigate("/projects");
  };

  const handleChangeTable = (pagination) => {
    setFetchAction(true);
    setPages({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleDelete = (id, companyName) => {
    setCompanyName(companyName);
    setInvestorInvitationId(id);
    setShowDelete(true);
  };

  const confirmDelete = (id) => {
    deleteInvestingCompany.reset();
    deleteInvestingCompany.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["investingCompaniesList", payload]);
        setShowDelete(false);
      },
      onError: (err) => {
        toast.error(
          t(
            `common:${
              err.response.data.result.code.message ||
              err.response.data.result.code
            }`
          )
        );
      },
    });
  };

  const handleClose = () => {
    setCompanyName("");
    setInvestorInvitationId("");
    setShowDelete(false);
  };

  const handleSwith = (id) => {
    sign.reset();
    sign.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["investingCompaniesList", payload]);
      },
      onError: (err) => {
        toast.error(
          t(
            `common:${
              err.response.data.result.code.message ||
              err.response.data.result.code
            }`
          )
        );
      },
    });
  };

  const handleDownloadOffer = () => {
    downloadFile(
      `documentation/download/${projectId}/7`,
      t("offer"),
      axiosClient,
      getToken()
    );
  };

  useEffect(() => {
    if (isSuccess) {
      const info = data?.data.result.data.data;

      setUsersList(
        info?.map((ele, indx) => ({
          key: indx,
          ...ele,
          actions: [
            {
              id: ele.investorId,
              name: ele.name,
              handleClick: () =>
                handleDelete(ele.investorInvitationId, ele.companyName),
              disabled: ele.indicativeOffer !== null,
              icon: (
                <DeleteIcon
                  width="32px"
                  height="32px"
                  fill={
                    ele.indicativeOffer !== null
                      ? theme.colors.disabled100
                      : theme.colors.green
                  }
                />
              ),
            },
          ],
        }))
      );
      setTotalItems(data?.data.result.data.total);
      setFetchAction(false);
    }
  }, [isSuccess, data]);

  const tableColumns = [
    {
      title: t("companyName"),
      dataIndex: "companyName",
      key: "companyName",
      width: "200px",
    },
    {
      title: t("financial"),
      dataIndex: "financialAgreement",
      key: "financialAgreement",
      align: "center",
      width: "250px",
      render: (value, items) => (
        <Div justify="center">
          <Switch
            checked={value}
            onChange={() => handleSwith(items.investorInvitationId)}
            loading={sign.isLoading}
          />
        </Div>
      ),
    },
    {
      title: projectType === "sale" ? t("offer") : t("ndaRequested"),
      dataIndex: projectType === "sale" ? "indicativeOffer" : "ndaRequested",
      key: projectType === "sale" ? "indicativeOffer" : "ndaRequested",
      width: "250px",
      render: (text) =>
        projectType === "sale" ? (
          text ? (
            <Row align="center" gap="10px">
              <Div>
                <Text color={theme.colors.green}>{`${text?.name?.slice(
                  0,
                  13
                )}...`}</Text>
              </Div>
              <Link onClick={handleDownloadOffer}>
                <EyeIcon fill={theme.colors.green} />
              </Link>
            </Row>
          ) : (
            <Text color={theme.colors.gray500}>-</Text>
          )
        ) : (
          <Text color={theme.colors.gray500}>{text ? t("yes") : "No"}</Text>
        ),
    },
    {
      title: t("invitationDate"),
      dataIndex: "invitationDate",
      key: "invitationDate",
      render: (text) => dateFormat(text),
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (actions) => (
        <Div justify="center">
          {actions.map((action, i) => (
            <Link
              key={i}
              onClick={action.handleClick}
              disabled={action.disabled}
            >
              {action.icon}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;

    setName(value);
  };

  const handleSearchPressEnter = (event) => {
    if (event.key === "Enter") {
      setFetchAction(true);
    }
  };

  return (
    <ModuleContainer direction="column" padding="16px 50px 0 32px">
      <Modal open={showDelete} onCancel={handleClose} width={"478px"}>
        <Modal.Header
          icon={deleteIcon}
          iconBackground={theme.colors.error100}
          iconBorderColor={theme.colors.error}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 63px 0px 63px"}
          title={t("modalDelete.title")}
          weightTitle={theme.fonts.weight.medium}
        />
        <Modal.Body margin="28px 0 32px 0">
          <Text
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
            style={{ textAlign: "center" }}
          >
            {`${t("modalDelete.message")} ${companyName}. ${t(
              "modalDelete.message2"
            )}`}
          </Text>
        </Modal.Body>
        <Modal.Footer gap="16px" align="center">
          <Button variant={"outlined"} width={"100%"} onClick={handleClose}>
            {t("modalDelete.cancel")}
          </Button>
          <Button
            width={"100%"}
            onClick={() => confirmDelete(investorInvitationId)}
            loading={deleteInvestingCompany.isLoading}
          >
            {t("modalDelete.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row align="center">
        <Link icon={<ArrowLeftIcon />} onClick={handleBack}>
          {t("goBack")}
        </Link>
      </Row>
      <Div
        direction="column"
        width="100%"
        height="auto"
        m="18px 0px 20px 0"
        p="18px 26px 30px 32px"
      >
        <Row width="100%" align="center" justify="space-between" m="0 0 27px 0">
          <Text
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("invitedInvestors")}
          </Text>
          <Input
            width="349px"
            placeholder={t("search")}
            suffix={<SearchIcon />}
            value={name}
            onChange={handleSearch}
            onPressEnter={handleSearchPressEnter}
            colorPlaceholder={theme.colors.gray200}
            radius="23px"
          />
        </Row>
        <Table
          columns={tableColumns}
          data={usersList}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            position: ["bottomRight"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: isLoading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((item, idx) => (
                  <Skeleton
                    key={idx}
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                  />
                ))}
              </Div>
            ) : (
              "No Data"
            ),
          }}
        />
      </Div>
      <Row align="center" justify="center" m="93px 0 0 0">
        <Text size={theme.fonts.size.sm} color={theme.colors.gray200}>
          {t("alter")}
        </Text>
      </Row>
    </ModuleContainer>
  );
};
