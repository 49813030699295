/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { useNavigate, useLocation } from "react-router-dom";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { CardHeader } from "../PlantDocuments/styles";
import {
  WrapperAdd,
  WrapperAddIcon,
  WrapperSociety,
  WrapperSubtitle,
} from "./styles";
import addIcon from "../../assets/icons/Add.svg";
import { ModalCorporation } from "./components/ModalCorporation";
import useGetSocieties from "./hooks/useGetSocieties";
import { IconButton } from "../../components/IconButton";
import deleteIcon from "../../assets/icons/delete-green.svg";
import editIcon from "../../assets/icons/Edit-green.svg";
import useDeleteSociety from "./hooks/useDeleteSociety";
import { ArrowRight } from "../../assets/icons";
import useGetPlantsList from "../NewProject/hooks/useGetPlants";
import useCreateSociety from "./hooks/useCreateSociety";
import useGetSociety from "./hooks/useGetSociety";
import useUpdateSociety from "./hooks/useUpdateSociety";
import { Row, Col } from "antd";
import { ModalDelete } from "./components/ModalDelete";
import { DrawerDocuments } from "./components/DrawerDocuments";

export const CorporateDocuments = () => {
  const { t } = useTranslation("documents");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectId = new URLSearchParams(search).get("projectId");
  const deleteSociety = useDeleteSociety();
  const createSociety = useCreateSociety();
  const updateSociety = useUpdateSociety();
  const { data, isSuccess, isLoading } = useGetSocieties(projectId);
  const [societiesList, setSocietiesList] = useState();
  const { data: dataPlants, isSuccess: isSuccessPlants } =
    useGetPlantsList(projectId);
  const [plantsList, setPlantsList] = useState([]);
  const [societyName, setSocietyName] = useState("");
  const [societyId, setSocietyId] = useState(null);
  const {
    data: dataSoc,
    isSuccess: isSuccessSoc,
    isLoading: isLoadingSoc,
    refetch,
  } = useGetSociety(societyId);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [societyForm, setSocietyForm] = useState({
    name: "",
    plants: [],
  });

  useEffect(() => {
    if (isSuccess) {
      setSocietiesList(data?.data.result.data.filter((soc) => soc.isActive));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessSoc) {
      setSocietyForm(dataSoc.data.result.data);
    }
  }, [isSuccessSoc, dataSoc]);

  useEffect(() => {
    if (isSuccessPlants && dataPlants?.data.length > 0) {
      setPlantsList(
        dataPlants.data.filter((objeto) => !objeto.hasOwnProperty("removedBy"))
      );
    }
  }, [isSuccessPlants, dataPlants]);

  const handleBack = () => {
    navigate(
      `/projects/documents?projectName=${projectName}&projectId=${projectId}`
    );
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenModalDelete(false);
    setSocietyId(null);
    setSocietyName("");
    if (societyId) {
      refetch();
    } else {
      setSocietyForm({
        name: "",
        plants: [],
      });
    }
  };

  const handleAdd = () => {
    setOpenModal(true);
    setSocietyForm({
      name: "",
      plants: [],
    });
  };

  const handleCreate = () => {
    const payload = {
      projectId: projectId,
      name: societyForm.name,
      plants: societyForm.plants,
      situacionLegal: "",
      isActive: true,
    };

    createSociety.reset();
    createSociety.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
          queryClient.invalidateQueries("getSocietiesList");
          setOpenModal(false);
          setSocietyForm({
            name: "",
            plants: [],
          });
        },
        onError: (err) => {
          toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
            "No ha sido posible guardar los documentos, por favor intenta nuevamente";
        },
      }
    );
  };

  const handleDeleteConfirm = (id) => {
    deleteSociety.reset();
    deleteSociety.mutate(id, {
      onSuccess: () => {
        toast.success(t("documentsSuccess"));
        queryClient.invalidateQueries("getSocietiesList");
        setOpenModalDelete(false);
        setSocietyId(null);
        setSocietyName("");
      },
      onError: (err) => {
        toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
          "No ha sido posible guardar los documentos, por favor intenta nuevamente";
      },
    });
  };

  const handleDelete = (id, name) => {
    setOpenModalDelete(true);
    setSocietyId(id);
    setSocietyName(name);
  };

  const handleUpdate = () => {
    delete societyForm.createdAt;
    delete societyForm.__v;

    updateSociety.reset();
    updateSociety.mutate(
      { ...societyForm },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
          queryClient.invalidateQueries("getSocietiesList");
          setOpenModal(false);
          setSocietyId(null);
          refetch();
        },
        onError: (err) => {
          toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
            "No ha sido posible guardar los documentos, por favor intenta nuevamente";
        },
      }
    );
  };

  const handleEdit = (id) => {
    setSocietyId(id);
    setOpenModal(true);
  };

  const handleCheck = (event) => {
    const { id } = event.target;

    setSocietyForm({ ...societyForm, plants: [...societyForm.plants, id] });
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newSocietyForm = { ...societyForm };

    newSocietyForm[id] = value;
    setSocietyForm(newSocietyForm);
  };

  const handleOpen = (id) => {
    setOpenDrawer(true);
    setSocietyId(id);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSocietyId(null);
  };

  return (
    <ModuleContainer direction="column" padding="17px 69px 19px 32px">
      <ModalCorporation
        open={openModal}
        handleClose={handleClose}
        plantsList={plantsList}
        handleCheck={handleCheck}
        handleChange={handleChange}
        societyForm={societyForm}
        handleAction={societyId ? handleUpdate : handleCreate}
        textButton={societyId ? t("updateSociety") : t("addSociety")}
        isloading={createSociety.isLoading || updateSociety.isLoading}
        isLoadingContent={isLoadingSoc}
      />
      <ModalDelete
        open={openModalDelete}
        handleClose={handleClose}
        handleDelete={handleDeleteConfirm}
        societyName={societyName}
        societyId={societyId}
        isLoading={deleteSociety.isLoading}
      />
      <DrawerDocuments
        openDrawer={openDrawer}
        handleClose={handleCloseDrawer}
        projectId={projectId}
        societyId={societyId}
      />
      <Link
        icon={<img alt="arrow" src={arrowLeft} />}
        onClick={handleBack}
        style={{ justifyContent: "left" }}
      >
        {t("back")}
      </Link>
      <CardHeader>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.white}
          weight={theme.fonts.weight.medium}
          mb="16px"
        >
          {t("important")}
        </Text>
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.white}
          lineHeight={"21px"}
        >
          {t("note1")}
          <br />
          {t("note2")}
          <br />
          {t("note3")}
          <br />
          {t("note4")}
        </Text>
      </CardHeader>
      <WrapperSubtitle>
        <Text color={theme.colors.gray300} lineHeight="25px">
          {t("corporateNote1")}
          <br />
          {t("corporateNote2")}
        </Text>
      </WrapperSubtitle>
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        <>
          <Row gutter={[23, 23]} style={{ width: "100%", marginTop: "32px" }}>
            {societiesList?.map((soc, indx) => (
              <Col key={indx} md={6}>
                <WrapperSociety>
                  <Div justify="space-between" m="0 0 14px 0">
                    <Text
                      color={theme.colors.blue}
                      size={theme.fonts.size.h6}
                      weight={theme.fonts.weight.medium}
                      style={{ width: "139px" }}
                    >
                      {soc.name.length > 20
                        ? `${soc.name.slice(0, 20)}...`
                        : soc.name}
                    </Text>
                    <Div gap="13px">
                      <IconButton
                        icon={deleteIcon}
                        width="37px"
                        height="37px"
                        onClick={() => handleDelete(soc._id, soc.name)}
                      />
                      <IconButton
                        icon={editIcon}
                        width="37px"
                        height="37px"
                        onClick={() => handleEdit(soc._id)}
                      />
                    </Div>
                  </Div>
                  <Div
                    align="center"
                    justify="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpen(soc._id)}
                  >
                    <Text
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray300}
                    >
                      {t("uploadDocs")}
                    </Text>
                    <Div align="center">
                      <Text
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                      >
                        0/6
                      </Text>
                      <ArrowRight fill={theme.colors.green} height={"24px"} />
                    </Div>
                  </Div>
                  <Div m="8px 0 0 0" align="center" justify="space-between">
                    <Text
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray300}
                    >
                      {t("incidents")}
                    </Text>
                    <Div align="center">
                      <Text
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                      >
                        0
                      </Text>
                      <ArrowRight fill={theme.colors.green} height={"24px"} />
                    </Div>
                  </Div>
                </WrapperSociety>
              </Col>
            ))}
            <Col md={6}>
              <WrapperAdd onClick={handleAdd}>
                <WrapperAddIcon>
                  <img alt="add" src={addIcon} />
                </WrapperAddIcon>
                <Text size={theme.fonts.size.sm} color={theme.colors.green}>
                  {t("addSociety")}
                </Text>
              </WrapperAdd>
            </Col>
          </Row>
        </>
      )}
      <Text
        color={theme.colors.gray300}
        size={theme.fonts.size.sm}
        style={{ textAlign: "center" }}
        mt="75px"
      >
        {t("common:poweredBy")}
      </Text>
    </ModuleContainer>
  );
};
