import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateInformationDeclaration = () => {
    const mutation = useMutation((formData) => {
        return axiosClient.put(`/informational-declaration/answer/list`, formData);
    });
    return mutation;
};

export default useUpdateInformationDeclaration;