import React from "react";
import PropTypes from 'prop-types';
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Input } from "../../../../components/Input";
import { Col, Row } from "antd";
import { getUserInfo } from "../../../../utilities/helpers";
import { Select } from "../../../../components/Select2";
import { FormatNumber, HandleChangeNumber } from "../../../../utilities/numberFunctions/handleChange";

export const TermsheetTable = ({
  title,
  options,
  t,
  marginTop,
  handleSelect,
  source,
  lang,
  termsheetForm,
  setTermsheetForm,
}) => {
  const { name } = getUserInfo();

  const priceCurveOptions = [
    { value: "Central curve", label: "Central curve" },
    { value: "Low curve", label: "Low curve" },
    { value: "Avg. central and low", label: "Avg. central and low" },
  ];

  return (
    <Div direction="column">
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h5i}
        color={theme.colors.blue}
        mb="36px"
        mt={marginTop}
      >
        {`${t(title)}`}
      </Text>
      <Row 
        style={{ 
          width: '50%',
          alignItems: 'center' 
        }}
      >
        <Col md={8} 
          style={{ 
            borderBottom: `1px solid ${theme.colors.gray100}`,
            height: '90px', 
          }}
        >
        </Col>
        <Col md={8} 
          style={{ 
            background: theme.colors.light,
            height: '90px',
            alignContent: 'center',
            textAlign: 'center',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <Text color={theme.colors.gray500}>
            <span 
              style={{ 
                color: theme.colors.green,
                fontSize: theme.fonts.size.default,
                fontWeight: theme.fonts.weight.medium, 
              }}
            >Alter 5:
          </span> 
          {" "}{t("table.indicativeTerms")}
          </Text>
        </Col>
        <Col md={8}
          style={{ 
            borderBottom: `1px solid ${theme.colors.gray100}`, 
            height: '90px',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <Text 
            weight={theme.fonts.weight.medium}
            color={theme.colors.blue}
          >
            <span 
              style={{ 
                color: theme.colors.green,
                fontSize: theme.fonts.size.default, 
                fontWeight: theme.fonts.weight.medium, 
              }}
            >
              {name || ''}
            </span>
            <br/>
            {t("table.suggestedTerms")}
          </Text>
        </Col>
      </Row>
      {options.map((ele, indx) => (
        <Row key={indx} 
          style={{ 
            width: '50%',
            alignItems: 'center' 
          }}
        >
          <Col md={8} 
            style={{ 
              borderBottom:  indx === options.length - 1 ? 'none' : `1px solid ${theme.colors.gray100}`,
              height: '90px', 
              alignContent: 'center',
            }}
          >
            <Text color={theme.colors.gray500}>
              {`${t(ele.label)}`}
            </Text>
            <Text 
              size={theme.fonts.size.xs}
              color={theme.colors.gray200}
            >
            {`${t(ele.description)}`}
            </Text>
          </Col>
          <Col md={8} 
            style={{ 
              background: theme.colors.light,
              height: '90px',
              alignContent: 'center',
              textAlign: 'center',
              borderBottomLeftRadius: indx === options.length - 1 ? '10px' : '0',
              borderBottomRightRadius: indx === options.length - 1 ? '10px' : '0'
            }}
          >
            <Text color={theme.colors.gray500}>
              {ele.alterValue}
            </Text>
          </Col>
          <Col md={8}
            style={{ 
              borderBottom:  indx === options.length - 1 ? 'none' : `1px solid ${theme.colors.gray100}`, 
              padding: '0 24px',
              height: '90px',
              alignContent: 'center',
              paddingBottom: '10px'
            }}
          >
            {source === "sizing" && indx === options.length - 1 ? (
              <Select 
                value={ele.value}
                options={priceCurveOptions}
                onChange={(value) => handleSelect(value, "clientPriceCurveScenario")}
                width={"100%"}
              />
            ) : (
              <Input 
                id={ele.id}
                value={FormatNumber(ele.value, lang)}
                onChange={(e) => HandleChangeNumber(e, termsheetForm, setTermsheetForm, lang, ele.type)}
                suffix={ele.suffix ? <img src={ele.suffix} alt="suffix" /> : null}
              />
            )}
          </Col>
        </Row>
      ))}
    </Div>
  )
};

TermsheetTable.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  t: PropTypes.any,
  marginTop: PropTypes.string,
  handleSelect: PropTypes.func,
  source: PropTypes.string,
  lang: PropTypes.string,
  termsheetForm: PropTypes.object,
  setTermsheetForm: PropTypes.func,
};