import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { AlertIcon } from "../../../../assets/icons";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";

const ModalTerms = ({ showModal, handleCloseModal, handleTerms }) => {
  const { t } = useTranslation("investorProjectDetail");

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        icon={
          <AlertIcon width={"50px"} height={"50px"} fill={theme.colors.green} />
        }
        iconBackground={theme.colors.green100}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 50px 0px 50px"}
        title={t("information")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Text size={theme.fonts.size.default} color={theme.colors.gray500} style={{ textAlign: 'center' }}>
          {t("access")}
        </Text>
      </Modal.Body>
      <Modal.Footer direction="column" gap="16px" justify="center" align="center" >
        <Button
          width="90%"
          height="45px"
          m="0 0 3px 0"
          onClick={handleTerms}
        >
          {t("terms")}
        </Button>
        <Link onClick={handleCloseModal}>
          {t("cancel")}
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

ModalTerms.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleTerms: PropTypes.func,
};

export default ModalTerms;
