import styled from 'styled-components';
import ReactQuill from 'react-quill';
import { theme } from '../../styles/theme';

export const StyledQuill = styled(ReactQuill)`
  .ql-container {
    font-family: ${theme.fonts.family};
    width: ${({ width }) => width || null};
    height: ${({ height }) => height || "90px"};
    border: 1px solid ${({ border }) => border || theme.colors.gray100};
    border-bottom-left-radius: ${({ radius }) => radius || "7px"};
    border-bottom-right-radius: ${({ radius }) => radius || "7px"};
    background: ${({ background }) => background || theme.colors.white};
    padding: 4px 0px;
    opacity: 1;
  }

  .ql-editor {
    font-family: ${theme.fonts.family};
    font-size: ${theme.fonts.size.sm};
  }

  .ql-toolbar {
    border-top-left-radius: ${({ radius }) => radius || "7px"};
    border-top-right-radius: ${({ radius }) => radius || "7px"};
    border: 1px solid ${({ border }) => border || theme.colors.gray100};

    .ql-stroke,
    .ql-fill {
      stroke: ${({ iconColor }) => iconColor || theme.colors.gray200};
    }

    .ql-stroke:hover {
      stroke: ${({ hoverIconColor }) => hoverIconColor || theme.colors.gray200} !important;
    }
  }

  .ql-snow.ql-toolbar .ql-stroke:hover {
    stroke: ${({ iconColor }) => iconColor || theme.colors.green} !important;
  }

  .ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: ${({ iconColor }) => iconColor || theme.colors.green} !important;
  }

  .ql-snow.ql-toolbar button.ql-active .ql-fill {
    stroke: ${({ iconColor }) => iconColor || theme.colors.green} !important;
  }


  .ql-snow .ql-picker-label::before {
    color: ${({ color }) => color || theme.colors.gray200};
  }
`;
