import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useContactAlterTeam = () => {
  const mutation = useMutation((projectId) => {
    return axiosClient.post("/project/contact-team-alter", { projectId });
  });
  return mutation;
};
export default useContactAlterTeam;
