import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCloseIncidence = () => {
  const mutation = useMutation((incidenceId) => {
    return axiosClient.patch(`/incidence/close/${incidenceId}`);
  });
  return mutation;
};
export default useCloseIncidence;
