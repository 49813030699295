import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { Div, Row, Text } from "../../../../styles/Common";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { Card } from "../Questions/styles";
import { useTranslation } from "react-i18next";
import useSendTermsheetComments from "../../hooks/useSendTermsheetComments";
import useGetTermsheet from "../../hooks/useGetTermsheet";
import { TermsheetTable } from "./TermsheetTable";
import years from "../../../../assets/icons/years.svg";
import percent from "../../../../assets/icons/percent.svg";
import { formatValue } from "../../../../utilities/numberFunctions/formatValue";
import { StringToNumber } from "../../../../utilities/numberFunctions/stringToNumber";
import { TextArea } from "../../../../components/TextArea";

export const IndicativeTermSheet = ({ projectId, activeKey }) => {
  const { t, i18n } = useTranslation(["indicativeTermSheet", "common"]);
  const lang = i18n.language;
  const send = useSendTermsheetComments();
  const { data, isSuccess, isLoading, refetch } = useGetTermsheet(projectId);
  const [termsheetForm, setTermsheetForm] = useState({
    projectId: projectId,
    tenor: "",
    spread: "",
    DSCRForMerchant: "",
    DSCRForPPA: "",
    leverage_cap: "",
    underlyingDebtTenor: "",
    priceCurveScenario: "",
    shortFinancingOpeningFee: "",
    clientTenor: "",
    clientSpread: "",
    clientShortFinancingOpeningFee: "",
    clientDSCRForMerchant: "",
    clientDSCRForPPA: "",
    clientLeverage_cap: "",
    clientUnderlyingDebtTenor: "",
    clientPriceCurveScenario: "",
    comment: "",
    associatedTermsheetId: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const withAssociatedTermsheetId = data?.data.filter(
        (item) => item.userId
      );
      const withoutAssociatedTermsheetId = data?.data.filter(
        (item) => !item.userId
      );

      const infoClient =
        withAssociatedTermsheetId[withAssociatedTermsheetId.length - 1];
      const info =
        withoutAssociatedTermsheetId[withoutAssociatedTermsheetId.length - 1];

      setTermsheetForm({
        projectId: info?.projectId || "",
        tenor: info?.tenor || "",
        spread: info?.spread || "",
        DSCRForMerchant: info?.DSCRForMerchant || "",
        DSCRForPPA: info?.DSCRForPPA || "",
        leverage_cap: info?.leverage_cap || "",
        underlyingDebtTenor: info?.underlyingDebtTenor || "",
        priceCurveScenario: info?.priceCurveScenario || "",
        shortFinancingOpeningFee: info?.shortFinancingOpeningFee || "",
        clientTenor: infoClient?.tenor || "",
        clientSpread: infoClient?.spread || "",
        clientDSCRForMerchant: infoClient?.DSCRForMerchant || "",
        clientDSCRForPPA: infoClient?.DSCRForPPA || "",
        clientLeverage_cap: infoClient?.leverage_cap || "",
        clientUnderlyingDebtTenor: infoClient?.underlyingDebtTenor || "",
        clientPriceCurveScenario: infoClient?.priceCurveScenario || "",
        clientShortFinancingOpeningFee:
          infoClient?.shortFinancingOpeningFee || "",
        comment: infoClient?.comment || "",
        associatedTermsheetId: infoClient?._id || "",
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setError(false);
  }, [activeKey]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newInfo = { ...termsheetForm };

    newInfo[id] = value;
    setTermsheetForm(newInfo);
    setError(false);
  };

  const handleSelect = (value, id) => {
    const newInfo = { ...termsheetForm };

    newInfo[id] = value;
    setTermsheetForm(newInfo);
  };

  const handleSend = () => {
    const payload = {
      projectId: termsheetForm.projectId,
      tenor: StringToNumber(termsheetForm.clientTenor),
      spread: StringToNumber(termsheetForm.clientSpread),
      DSCRForMerchant: termsheetForm.clientDSCRForMerchant
        ? StringToNumber(termsheetForm.clientDSCRForMerchant).toString()
        : "",
      DSCRForPPA: termsheetForm.clientDSCRForPPA
        ? StringToNumber(termsheetForm.clientDSCRForPPA).toString()
        : "",
      leverage_cap: termsheetForm.clientLeverage_cap
        ? StringToNumber(termsheetForm.clientLeverage_cap).toString()
        : "",
      underlyingDebtTenor: termsheetForm.clientUnderlyingDebtTenor
        ? StringToNumber(termsheetForm.clientUnderlyingDebtTenor).toString()
        : "",
      priceCurveScenario: termsheetForm.clientPriceCurveScenario,
      shortFinancingOpeningFee: StringToNumber(
        termsheetForm.clientShortFinancingOpeningFee
      ),
      comment: termsheetForm.comment,
      associatedTermsheetId: termsheetForm.associatedTermsheetId,
    };

    if (payload.comment === "") {
      toast.error(t("verifyFields"));
      setError(true);
    } else {
      setError(false);
      send.reset();
      send.mutate(payload, {
        onSuccess: () => {
          toast.success(t("savedSuccessfully"));
          refetch();
        },
        onError: (err) => {
          toast.error(t(`common:${err.response.data.result.code}`));
        },
      });
    }
  };

  if (isLoading) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  const mainParameters = [
    {
      id: "clientTenor",
      label: "table.legalTenor",
      alterValue: `${
        termsheetForm?.tenor
          ? `${formatValue(termsheetForm?.tenor, lang)} ${
              termsheetForm?.tenor === 1 ? t("table.year") : t("table.years")
            }`
          : "-"
      }`,
      value: termsheetForm?.clientTenor,
      suffix: years,
      type: "years",
    },
    {
      id: "clientSpread",
      label: "table.spread",
      alterValue: `${
        termsheetForm?.spread
          ? `${formatValue(termsheetForm?.spread, lang)} %`
          : "-"
      }`,
      value: termsheetForm?.clientSpread,
      suffix: percent,
      type: "percentage",
    },
    {
      id: "clientShortFinancingOpeningFee",
      label: "table.upFrontFee",
      alterValue: `${
        termsheetForm?.shortFinancingOpeningFee
          ? `${formatValue(termsheetForm?.shortFinancingOpeningFee, lang)} %`
          : "-"
      }`,
      value: termsheetForm?.clientShortFinancingOpeningFee,
      suffix: percent,
      type: "percentage",
    },
  ];

  const sizingParameters = [
    {
      id: "clientDSCRForMerchant",
      label: "table.dscrMerchant",
      description: "",
      alterValue: formatValue(termsheetForm?.DSCRForMerchant, lang) || "-",
      value: termsheetForm?.clientDSCRForMerchant,
      type: "number",
    },
    {
      id: "clientDSCRForPPA",
      label: "table.dscrPpa",
      description: "table.dscrPpaCondition",
      alterValue: `${
        termsheetForm?.DSCRForPPA
          ? formatValue(termsheetForm?.DSCRForPPA, lang)
          : "-"
      } `,
      value: termsheetForm?.clientDSCRForPPA,
      type: "number",
    },
    {
      id: "clientUnderlyingDebtTenor",
      label: "table.underlyingDebt",
      description: "",
      alterValue: `${
        termsheetForm?.underlyingDebtTenor
          ? `${formatValue(termsheetForm?.underlyingDebtTenor, lang)} ${
              termsheetForm?.underlyingDebtTenor === 1
                ? t("table.year")
                : t("table.years")
            }`
          : "-"
      }`,
      value: termsheetForm?.clientUnderlyingDebtTenor,
      suffix: years,
      type: "years",
    },
    {
      id: "clientLeverage_cap",
      label: "table.leverageCap",
      description: "",
      alterValue: `${
        termsheetForm?.leverage_cap
          ? formatValue(termsheetForm?.leverage_cap, lang) + "%"
          : "-"
      } `,
      value: termsheetForm?.clientLeverage_cap,
      suffix: percent,
      type: "percentage",
    },
    {
      id: "clientPriceCurveScenario",
      label: "table.energyPriceCurve",
      description: "",
      alterValue: termsheetForm?.priceCurveScenario || "-",
      value: termsheetForm?.clientPriceCurveScenario,
      type: "select",
    },
  ];

  return (
    <>
      <Div direction="column" style={{ paddingBottom: "6.25rem" }}>
        <Row justify="space-between" align="center" m="0 0 23px 0">
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
          >
            {t("title")}
          </Text>
        </Row>
        <Card
          width="100%"
          height="auto"
          padding="23px 27px 36px 24px"
          margin="0 0 50px 0"
        >
          <Div direction="column">
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h5i}
              color={theme.colors.blue}
              mb="16px"
            >
              {t("cTitle")}
            </Text>
          </Div>
          <Div direction="column">
            <Text color={theme.colors.gray300} mb="24px">
              {t("cTellUsYourComment")}
            </Text>
            <Text
              color={theme.colors.gray200}
              mb="8px"
              style={{
                fontWeight: theme.fonts.weight.regular,
              }}
            >
              {t("cOtherComments")}
            </Text>
            <TextArea
              id="comment"
              value={termsheetForm.comment}
              onChange={handleChange}
              rows={6}
              width="50%"
              error={error}
              helper={error ? t("common:requiredField") : ""}
            />
            <TermsheetTable
              title={"table.mainParameters"}
              options={mainParameters}
              t={t}
              marginTop={"36px"}
              lang={lang}
              termsheetForm={termsheetForm}
              setTermsheetForm={setTermsheetForm}
            />
            <TermsheetTable
              title={"table.sizingParameters"}
              options={sizingParameters}
              t={t}
              marginTop={"73px"}
              handleSelect={handleSelect}
              source={"sizing"}
              lang={lang}
              termsheetForm={termsheetForm}
              setTermsheetForm={setTermsheetForm}
            />
            <Button
              m="32px 0 0 0"
              width="201px"
              height="45px"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "23px",
                gap: "4px",
                fontSize: theme.fonts.size.sm,
              }}
              onClick={handleSend}
              loading={send.isLoading}
            >
              {t("cSendComments")}
            </Button>
          </Div>
        </Card>
      </Div>
      <style>
        {`
          .placeholder-right::placeholder {
            text-align: right;
          }
        `}
      </style>
    </>
  );
};

IndicativeTermSheet.propTypes = {
  projectId: PropTypes.string,
  activeKey: PropTypes.string,
};

export default IndicativeTermSheet;
