import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Radio } from "../../../components/Radio";
import { Text, Div, Col } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { theme } from "../../../styles/theme";
import { formatNumber, getUserInfo } from "../../../utilities/helpers";
import { AlertIcon } from "../../../assets/icons";

export const Capex = ({
  capexInfo,
  setCapexInfo,
  projectType,
  handleChangeFormat,
  errorForm,
  isEdit,
  setOpenDrawer,
  setField,
  setFieldId,
  incidence
}) => {
  const { t, i18n } = useTranslation("newProject");
  const locale = i18n.language;
  const role = getUserInfo().role[0];
  const [selection, setSelection] = useState(capexInfo.projectCostsOption);

  const handleSelectionChange = useCallback(
    (key) => {
      if (selection !== key) {
        const newCapexInfo = { ...capexInfo };
        newCapexInfo.projectCostsOption = key;
        if (key === "sponsorTotals") {
          Object.keys(newCapexInfo).forEach((field) => {
            if (field !== "projectCostsOption") {
              newCapexInfo[field] = "";
            }
          });
        } else if (key === "sponsorDetails") {
          newCapexInfo["totalProjectCost"] = "";
        }

        setCapexInfo(newCapexInfo);
        setSelection(key);
      }
    },
    [selection, capexInfo, setCapexInfo]
  );

  const closedIncidence = incidence && Object.keys(incidence).length > 0 && incidence.status === 'Closed';

  const renderSuffix = () => {
    return (
      <Div align="center" justify="center" style={{ gap: "4px" }}>
        € 
        {selection === "sponsorTotals" && (
          <AlertIcon 
            fill={closedIncidence ? theme.colors.green500 : theme.colors.yellow700} 
            width={"24px"}
            height={"24px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenDrawer(true);
              setField(t("capex.totalProjectCost"));
              setFieldId("Capex");
            }}
          />
        )}
      </Div>
    );
  };

  const shouldRenderSuffix = () => {
    if (!isEdit) return false;
    if (role === "Developer") {
      return incidence && Object.keys(incidence).length > 0;
    }
    return true;
  };

  const inputConfigs = [
    {
      key: "sponsorTotals",
      condition: (selection) => selection === "sponsorTotals",
      render: (t) => (
        <Input
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("capex.totalProjectCost")}
          id="totalProjectCost"
          suffix={shouldRenderSuffix() ? renderSuffix() : "€"}
          value={
            formatNumber(capexInfo["totalProjectCost"], locale) === "0"
              ? ""
              : formatNumber(capexInfo["totalProjectCost"], locale)
          }
          onChange={(e) => handleChangeFormat(e, capexInfo, setCapexInfo)}
          min="0"
          width="50%"
          error={errorForm.capexInfo.totalProjectCost}
        />
      ),
    },
    {
      key: "sponsorDetails",
      condition: (selection) => selection === "sponsorDetails",
      render: (t) => (
        <Div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          {Object.entries({
            substationReinforcementCost: t("capex.substationReinforcementCost"),
            transmissionLineCost: t("capex.transmissionLineCost"),
            solarPanelsCosts: t("capex.totalCostSolarPanels"),
            totalTransformersCost: t("capex.totalTransformersCost"),
            invertersCosts: t("capex.powerInverterCost"),
            additionalInvestmentCosts: t("capex.additionalInvestmentCosts"),
          })
            .filter(([id]) => {
              if (id === "solarPanelsCosts" || id === "invertersCosts") {
                return projectType === "solar";
              }
              return true;
            })
            .map(([id, label]) => (
              <Input
                key={id}
                sizeLabel={theme.fonts.size.default}
                weightLabel={theme.fonts.weight.regular}
                label={label}
                id={id}
                suffix={"€"}
                value={
                  formatNumber(capexInfo[id], locale) === "0"
                    ? ""
                    : formatNumber(capexInfo[id], locale)
                }
                onChange={(e) => handleChangeFormat(e, capexInfo, setCapexInfo)}
                min="0"
                error={errorForm.capexInfo[id]}
              />
            ))}
          {projectType === "eolic" && (
            <Input
              key={"totalTurbinesCost"}
              sizeLabel={theme.fonts.size.default}
              weightLabel={theme.fonts.weight.regular}
              label={t("capex.totalTurbinesCost")}
              id={"totalTurbinesCost"}
              suffix={"€"}
              value={
                formatNumber(capexInfo["totalTurbinesCost"], locale) === "0"
                  ? ""
                  : formatNumber(capexInfo["totalTurbinesCost"], locale)
              }
              onChange={(e) => handleChangeFormat(e, capexInfo, setCapexInfo)}
              min="0"
              error={errorForm.capexInfo.totalTurbinesCost}
            />
          )}
        </Div>
      ),
    },
  ];

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h6}
        color={theme.colors.blue}
        mb="32px"
      >
        {t("CAPEX")}
      </Text>
      <Col style={{ gap: "8px" }}>
        {inputConfigs.map(({ key, render, condition }) => (
          <React.Fragment key={key}>
            <Radio
              name="capex"
              label={
                key === "sponsorTotals"
                  ? t(`capex.totalExpenses`)
                  : t(`capex.detailedExpenses`)
              }
              value={key}
              checked={selection === key}
              onChange={() => handleSelectionChange(key)}
            />
            {condition(selection) ? render(t) : null}
          </React.Fragment>
        ))}
      </Col>
    </>
  );
};

Capex.propTypes = {
  capexInfo: PropTypes.object,
  setCapexInfo: PropTypes.func,
  projectType: PropTypes.string,
  handleChangeFormat: PropTypes.func,
  errorForm: PropTypes.object,
  isEdit: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  setField: PropTypes.func,
  setFieldId: PropTypes.func,
  incidence: PropTypes.object,
};
