import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { LandsOptions } from "../../../utilities/plantsOptions";
import { Col, Row } from "antd";
import mwn from "../../../assets/icons/mwn.svg";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";
import DatePicker from "../../../components/DatePicker";
import { Div } from "../../../styles/Common";
import { AlertIcon } from "../../../assets/icons";
import { theme } from "../../../styles/theme";
import { DrawerAlert } from "../../EditProject/components/DrawerAlert";
import { getUserInfo } from "../../../utilities/helpers";

export const CommonFields = ({
  financingType,
  projectType,
  objective,
  plantsInfo,
  mwp,
  years,
  anual,
  ha,
  handleChange,
  handleSelect,
  formError,
  provincesOptions,
  municipalityOptions,
  setPlantsInfo,
  handleDeleteError,
  handleDate,
  plantId,
  incidencesList,
}) => {
  const { t, i18n } = useTranslation("newPlant");
  const locale = i18n.language;
  const role = getUserInfo().role[0];
  const [openDrawer, setOpenDrawer] = useState(false);
  const [field, setField] = useState("");
  const [fieldId, setFieldId] = useState("");

  const currentPlantIncidences = incidencesList?.filter(incidence => incidence.plantId === plantId || incidence.plantId === null);

  const productionP50 =
    projectType === "solar" && objective === "financing";
  const lands =
    plantsInfo.estadoTerrenoPlanta === 2 ||
    plantsInfo.estadoTerrenoPlanta === 3;

  const startUpField = objective === "sale" && financingType === "operation";

  const shouldRenderSuffix = (id) => {
    if (!plantId) return false;
    if (role === "Developer") {
      return currentPlantIncidences.some(incidence => incidence.projectField === id);
    }
    return true;
  };

  const renderSuffix = (icon, name, id) => {
    const isClosed = currentPlantIncidences?.filter(incidence => incidence.projectField === id)?.slice(-1)[0]?.status === 'Closed';
    
    return (
      <Div align="center" justify="center" style={{ gap: "4px" }}>
        <img alt="icon" src={icon} /> 
        <AlertIcon 
          fill={isClosed ? theme.colors.green500 : theme.colors.yellow700}
          width={"24px"}
          height={"24px"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpenDrawer(true);
            setField(t(name));
            setFieldId(id);
          }}
        />
      </Div>
    );
  };
  
  return (
    <Row gutter={[32, 32]}>
      <DrawerAlert
        openDrawer={openDrawer}
        handleClose={() => setOpenDrawer(false)}
        field={field}
        fieldId={fieldId}
        incidencesList={currentPlantIncidences}
        plantId={plantId}
        plantName={plantsInfo?.name}
        allIncidences={incidencesList}
        source="plants"
      />
      <Col md={8}>
        <Input
          id={"name"}
          label={t("plantName")}
          value={plantsInfo.name}
          onChange={handleChange}
          error={formError.name.error}
          helper={formError.name.error ? formError.name.message : ""}
        />
      </Col>
      <Col md={8}>
        <Select
          id={"location"}
          label={t("province")}
          options={provincesOptions}
          placeholder={`${t("choose")}...`}
          width={"100%"}
          value={plantsInfo.location}
          onChange={(event) => handleSelect(event, "location")}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
        />
      </Col>
      <Col md={8}>
        <Select
          id={"municipio"}
          label={t("municipality")}
          options={municipalityOptions}
          placeholder={`${t("choose")}...`}
          width={"100%"}
          value={plantsInfo.municipio}
          onChange={(event) => handleSelect(event, "municipio")}
          disabled={plantsInfo.location === null}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
        />
      </Col>
      <Col md={8}>
        <Input
          id={"potenciaPico"}
          label={t("peakPower")}
          suffix={shouldRenderSuffix("Peak power") ? renderSuffix(mwp, "peakPower", "Peak power") : <img alt="mwp" src={mwp} />}
          value={FormatNumber(plantsInfo.potenciaPico, locale)}
          onChange={(e) => {
            HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale);
            handleDeleteError(e);
          }}
          error={formError.potenciaPico.error}
          helper={
            formError.potenciaPico.error ? formError.potenciaPico.message : ""
          }
        />
      </Col>
      <Col md={8}>
        <Input
          id="potenciaNominal"
          label={t("ratedPower")}
          suffix={<img alt="mwn" src={mwn} />}
          value={FormatNumber(plantsInfo.potenciaNominal, locale)}
          onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
        />
      </Col>
      {productionP50 && (
        <Col md={8}>
          <Input
            id={"horasEquivalentes"}
            label={t("productionP50")}
            value={FormatNumber(plantsInfo.horasEquivalentes, locale)}
            onChange={(e) => {
              HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale);
              handleDeleteError(e);
            }}
            error={formError.horasEquivalentes.error}
            helper={
              formError.horasEquivalentes.error
                ? formError.horasEquivalentes.message
                : ""
            }
          />
        </Col>
      )}
      <Col md={8}>
        <Input
          id={"horasEquivalentes90"}
          label={t("productionP90")}
          value={FormatNumber(plantsInfo.horasEquivalentes90, locale)}
          onChange={(e) => {
            HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale);
            handleDeleteError(e);
          }}
          error={formError.horasEquivalentes90.error}
          helper={
            formError.horasEquivalentes90.error
              ? formError.horasEquivalentes90.message
              : ""
          }
        />
      </Col>
      <Col md={8}>
        <Select
          label={t("land")}
          placeholder={`${t("choose")}...`}
          width={"100%"}
          options={LandsOptions}
          value={plantsInfo.estadoTerrenoPlanta}
          onChange={(event) => handleSelect(event, "estadoTerrenoPlanta")}
          style={{ marginBottom: !lands && "32px" }}
        />
      </Col>
      {lands && (
        <>
          <Col md={8}>
            <Input
              id={"plazoContratoArrendamiento"}
              label={t("leaseTerm")}
              suffix={<img alt="years" src={years} />}
              value={FormatNumber(plantsInfo.plazoContratoArrendamiento, locale)}
              onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
            />
          </Col>
          <Col md={8}>
            <Input
              id={"costeArrendamiento"}
              label={t("annualLeaseCost")}
              suffix={shouldRenderSuffix("Annually lease cost") ? renderSuffix(anual, "annualLeaseCost", "Annually lease cost") : <img alt="anual" src={anual} />}
              value={FormatNumber(plantsInfo.costeArrendamiento, locale)}
              onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
            />
          </Col>
        </>
      )}
      <Col md={8}>
        <Input
          id={"superficie"}
          label={t("plantSurface")}
          suffix={shouldRenderSuffix("Plant surface") ? renderSuffix(ha, "plantSurface", "Plant surface") : <img alt="ha" src={ha} />}
          value={FormatNumber(plantsInfo.superficie, locale)}
          onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
          style={{ marginBottom: "32px" }}
        />
      </Col>
      {startUpField && (
        <Col md={8}>
          <DatePicker
            label={t("startUpDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaPuestaEnMarcha}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaPuestaEnMarcha")}
          />
        </Col>
      )}
    </Row>
  );
};

CommonFields.propTypes = {
  plantsInfo: PropTypes.object,
  projectType: PropTypes.string,
  objective: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  formError: PropTypes.object,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
  setPlantsInfo: PropTypes.func,
  handleDeleteError: PropTypes.func,
  financingType: PropTypes.string,
  plantId: PropTypes.number,
  incidencesList: PropTypes.array,
};
