import React, { useState } from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text, Div } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { downloadFile, getToken } from "../../../../utilities/helpers";
import { axiosClient } from "../../../../core/services/axiosInstance";
import { Row, Col } from "antd";

const ModalStrategies = ({
  showModal,
  handleCloseModal,
  data,
  teaserId,
  language,
}) => {
  const { t } = useTranslation("projectTable");
  const [loading, setLoading] = useState(false);

  const downloadTeaser = async (strategyId) => {
    setLoading(true);
    const success = await downloadFile(
      `teaser/${teaserId}/pdf/${strategyId}?lang=${language}`,
      "Teaser",
      axiosClient,
      getToken()
    );

    setLoading(false);
    if (success) {
      handleCloseModal()
    }
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        align="-webkit-left"
        padding={"0px 50px 0px 15px"}
        title={t("downloadTeaser")}
        sizeTitle={theme.fonts.size.h5i}
        weightTitle={theme.fonts.weight.medium}
      />
      <Modal.Body
        margin="28px 0 0 0"
        padding="0 20px"
        align={"center"}
        style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        {loading ? (
          <Div justify="center" align="center">
            <ReactLoading color={theme.colors.green} />
          </Div>
        ) : (
          <>
            <Text
              size={theme.fonts.size.h6}
              color={theme.colors.blue}
              weight={theme.fonts.weight.medium}
              mb="24px"
            >
              {t("select")}
            </Text>
            <Row
              gutter={[16, 16]}
              style={{ 
                width: "100%",
                display: "flex",
                justifyContent: data.length % 2 === 1 ? "center" : "flex-start"
              }}
            >
              {data.length > 0 ? (
                data.map((item, indx) => (
                  <Col md={12} key={indx}>
                    <Div
                      width="183px"
                      height="47px"
                      border="1px solid"
                      borderColor={theme.colors.green}
                      radius="8px"
                      align="center"
                      justify="center"
                      style={{ cursor: "pointer" }}
                      onClick={
                        () => downloadTeaser(item?.transactionSummary?.strategyId)
                      }
                    > 
                      <Text color={theme.colors.green}>
                        {item?.transactionSummary?.strategyName}
                      </Text>
                    </Div>
                  </Col>
                ))
              ) : (
                <Text>{t("noStrategy")}</Text>
              )}
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

ModalStrategies.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  data: PropTypes.array,
  teaserId: PropTypes.string,
  language: PropTypes.string,
};

export default ModalStrategies;
