import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCreateComments = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.post(`/incidence/new-comment/${formData.incidenceId}`, { comment: formData.comment });
  });
  return mutation;
};
export default useCreateComments;
