import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteProject = () => {
  const mutation = useMutation((projectId) => {
    return axiosClient.delete(`/project/delete/${projectId}`);
  });
  return mutation;
};
export default useDeleteProject;
