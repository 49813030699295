import styled from "styled-components";

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.yellow100};
`;

export const WrapperText = styled.div`
  display: flex;
  align-items: center;
  width: 456px;
  height: 50px;
  background: #FFFCEA 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  padding-left: 10px;
  gap: 12px;
`;
