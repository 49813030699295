import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSendNda = () => {
  const mutation = useMutation((projectId) => {
    return axiosClient.post("/project/send-nda", { projectId });
  });
  return mutation;
};
export default useSendNda;
