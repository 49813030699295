import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col } from "../../../styles/Common";
import { Modal } from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select2";
import toast from "react-hot-toast";
import useAssignPendingUser from "../hooks/useAssignPendingUser";
import useGetAlter5ParentCompanies from "../hooks/useGetAlter5ParentCompanies";

const ModalAssociateCompany = ({
  showModal,
  handleCloseModal,
  preSelectedCompany,
  setPreSelectedCompany,
  setTabActiveKey,
  setSearchClicked,
  selectedProjectReq,
}) => {
  const { t } = useTranslation("scraper");
  const associatePendingUser = useAssignPendingUser();

  const getMotherCompanies = useGetAlter5ParentCompanies();
  const { data, isSuccess } = getMotherCompanies;
  const [companiesOptions, setCompaniesOptions] = useState([]);
  useEffect(() => {
    if (isSuccess && data?.data) {
      setCompaniesOptions(
        data.data.map((company) => ({
          label: company.name,
          value: company._id,
        }))
      );
    }
  }, [data, isSuccess]);

  const handleSelect = (value, option) => {
    setPreSelectedCompany(option);
  };

  const handleCloseAndReset = () => {
    setPreSelectedCompany({});
    handleCloseModal();
  };

  const handleAssociate = () => {
    associatePendingUser.reset();
    associatePendingUser.mutate(
      {
        userId: selectedProjectReq?.createdBy,
        companyId: preSelectedCompany?.value,
      },
      {
        onSuccess: () => {
          toast.success(t("associateSuccess"));
          setTabActiveKey("1");
          setSearchClicked(true);
          handleCloseModal();
        },
        onError: (err) => {
          toast.error(
            err.response.data.result.code?.message ||
              err.response.data.result.errorMessage?.message
          );
        },
      }
    );
  };

  return (
    <Modal open={showModal} onCancel={handleCloseAndReset}>
      <Modal.Header
        iconBackground={theme.colors.white}
        iconBorderColor={theme.colors.red}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 20px 0px 20px"}
        title={t("associateTitle")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="12px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col
          width="100%"
          height="100%"
          align="center"
          justify="center"
          gap="24px"
        >
          <Select
            label={t("parentCompany")}
            sizeLabel={theme.fonts.size.default}
            value={preSelectedCompany?.value}
            onChange={handleSelect}
            options={companiesOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          />
          <Button
            width="150px"
            onClick={handleAssociate}
            disabled={!preSelectedCompany}
            loading={associatePendingUser.isLoading}
          >
            {t("associateButton")}
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalAssociateCompany.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  setPreSelectedCompany: PropTypes.func,
  preSelectedCompany: PropTypes.string,
  setTabActiveKey: PropTypes.func,
  setSearchClicked: PropTypes.func,
  selectedProjectReq: PropTypes.object,
};

export default ModalAssociateCompany;
