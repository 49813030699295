import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "projects",
  initialState: {
    projectInCreation: {},
    ownerInfo: {
      owner: "",
      ownerId: "",
    },
    incidences: [],
  },
  reducers: {
    setProjectInCreation: (state, action) => {
      return {
        ...state,
        projectInCreation: action.payload,
      };
    },
    setOwnerInfo: (state, action) => {
      return {
        ...state,
        ownerInfo: action.payload,
      };
    },
    setIncidences: (state, action) => {
      return {
        ...state,
        incidences: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setProjectInCreation, setOwnerInfo, setIncidences } = slice.actions;

export const setProjectInCreationInfo = (data) => async (dispatch) => {
  dispatch(setProjectInCreation(data));
};
