import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetAlter5ParentCompanies = () => {
  const data = useQuery(["getAlter5ParentCompanies"], () =>
    axiosClient.get(`company/parent-companies`)
  );

  return data;
};
export default useGetAlter5ParentCompanies;
