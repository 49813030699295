import React, { useState, useEffect } from "react";
import { theme } from "../../styles/theme";
import { ModuleContainer, Row, Text } from "../../styles/Common";
import Tabs from "../../components/Tabs";
import { DrawerContacts } from "./components/DrawerContacts";
import { DrawerEditInfo } from "./components/DrawerEditInfo";
import { DrawerAditionalInfo } from "./components/DrawerAditionalInfo";
import { PendingRequest } from "./components/PendingRequest";
import { useTranslation } from "react-i18next";
import { SearchScraper } from "./components/SearchScraper";
import ModalDelete from "./components/ModalDelete";
import useGetMotherCompanies from "./hooks/useGetMotherCompanies";
import ModalAssociateCompany from "./components/ModalAssociateCompany";

export const Scraper = () => {
  const { t } = useTranslation("scraper");
  const [tabActiveKey, setTabActiveKey] = useState("1");

  const [selectedScraper, setSelectedScraper] = useState({});
  const [preSelectedCompany, setPreSelectedCompany] = useState({});
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedProjectReq, setSelectedProjectReq] = useState({});
  const [showDrawerEditInfo, setShowDrawerEditInfo] = useState(false);

  const initialEditErrors = {
    name: { error: false, message: null },
    peakPower: { error: false, message: null },
    promoterCompany: { error: false, message: null },
    motherCompany: { error: false, message: null },
  };
  const [editInfoErrorForm, setEditInfoErrorForm] = useState(initialEditErrors);

  const [showDrawerAdditionalInfo, setShowDrawerAdditionalInfo] =
    useState(false);
  const [showDrawerContacts, setShowDrawerContacts] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalAssociateComapany, setShowModalAssociateComapany] =
    useState(false);

  const getMotherCompanies = useGetMotherCompanies();
  const { data, isSuccess } = getMotherCompanies;
  const [companiesOptions, setCompaniesOptions] = useState([]);
  useEffect(() => {
    if (isSuccess && data?.data?.data) {
      setCompaniesOptions(
        data.data.data.map((company) => ({
          label: company.name,
          value: company.id,
        }))
      );
    }
  }, [data, isSuccess]);

  const handleTabChange = (key) => {
    setTabActiveKey(key);
  };
  const panels = [
    {
      tab: t("scraper"),
      key: "1",
    },
    {
      tab: t("pendingRequest"),
      key: "2",
    },
  ];

  const handleCloseDrawer = () => {
    setEditInfoErrorForm(initialEditErrors);
    setSelectedScraper({});
    setShowDrawerEditInfo(false);
    setShowDrawerAdditionalInfo(false);
    setShowDrawerContacts(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalAssociateComapany(false);
    setSelectedProjectReq({});
  };

  return (
    <ModuleContainer direction="column" padding="16px 50px 0 32px">
      <DrawerContacts
        openDrawer={showDrawerContacts}
        handleClose={handleCloseDrawer}
        selectedScraper={selectedScraper}
      />
      <DrawerEditInfo
        openDrawer={showDrawerEditInfo}
        handleClose={handleCloseDrawer}
        selectedScraper={selectedScraper}
        editInfoErrorForm={editInfoErrorForm}
        setEditInfoErrorForm={setEditInfoErrorForm}
        companiesOptions={companiesOptions}
      />
      <DrawerAditionalInfo
        openDrawer={showDrawerAdditionalInfo}
        handleClose={handleCloseDrawer}
        selectedScraper={selectedScraper}
      />
      <ModalDelete
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        page={"scraper"}
        selectedScraper={selectedScraper}
      />
      <ModalAssociateCompany
        showModal={showModalAssociateComapany}
        handleCloseModal={handleCloseModal}
        preSelectedCompany={preSelectedCompany}
        setPreSelectedCompany={setPreSelectedCompany}
        setTabActiveKey={setTabActiveKey}
        setSearchClicked={setSearchClicked}
        selectedProjectReq={selectedProjectReq}
      />
      <Row>
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
          activeKey={tabActiveKey}
          color={theme.colors.gray500}
        >
          {panels.map((panel) => (
            <Tabs.Panel tab={panel.tab} key={panel.key} />
          ))}
        </Tabs>
      </Row>
      {tabActiveKey === "1" && (
        <SearchScraper
          setShowDrawerEditInfo={setShowDrawerEditInfo}
          selectedScraper={setSelectedScraper}
          setShowDrawerAdditionalInfo={setShowDrawerAdditionalInfo}
          setShowDrawerContacts={setShowDrawerContacts}
          preSelectedCompany={preSelectedCompany}
          searchClicked={searchClicked}
          setSearchClicked={setSearchClicked}
          setShowModal={setShowModal}
          companiesOptions={companiesOptions}
        />
      )}
      {tabActiveKey === "2" && (
        <PendingRequest
          setPreSelectedCompany={setPreSelectedCompany}
          setShowModalAssociateComapany={setShowModalAssociateComapany}
          setSelectedProjectReq={setSelectedProjectReq}
        />
      )}

      <Row align="end" justify="center" height="100%" m=" 0 0 20px 0">
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.gray200}
          style={{ margin: "45px auto 0" }}
        >
          {t("alter")}
        </Text>
      </Row>
    </ModuleContainer>
  );
};
